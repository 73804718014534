import { useEffect, useState } from "react";
import { Navbar } from "../../layout/Navbar";
import { Sidebar } from "../../layout/Sidebar";
import SellersJsonForm from "./SellersJsonForm";
import ProcessDisplay from "./ProcessDisplay";
import ResultsDisplay from "./ResultsDisplay";
import loading from "../../assets/css/loading.json";
import { useAuth } from "../../auth/AuthContext";
import { Navigate } from "react-router";
import Lottie from "lottie-react";

// Crawler component to handle the crawling process of Sellers.json and Ads.txt
export const Crawler = () => {
  // State variables for sidebar, results, progress, crawling status, manual lines, and loading
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); 
  const [results, setResults] = useState([]); 
  const [progress, setProgress] = useState(null);
  const [isCrawling, setIsCrawling] = useState(false);
  const [manualLines, setManualLines] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  // Get the current user from the authentication context
  const { currentUser } = useAuth();

  // Effect to handle loading state based on authentication
  useEffect(() => {
    if (currentUser) {
      setShowLoading(false);
    }
  }, [currentUser]);

  // Redirect to login if user is not authenticated
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // Handle new results from the crawling process
  const handleResults = (newResult) => {
    setResults((prevResults) => [...prevResults, newResult]);
  };

  // Handle progress updates from the crawling process
  const handleProgress = (newProgress) => {
    setProgress(newProgress);
    setIsCrawling(
      newProgress.status === "fetching" || newProgress.status === "processing"
    );
  };

  // Handle manual lines input from the user
  const handleManualLines = (lines) => {
    setManualLines(lines);
  };

  // Function to toggle the sidebar open/close state
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Reset Crawler State Function
  const resetCrawlerState = () => {
    setResults([]);
    setProgress(null);
    setIsCrawling(false);
  };
  return (
    <div>
      {/* Navbar with sidebar toggle */}
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="flex flex-row">
        {/* Sidebar */}
        <Sidebar isOpen={isSidebarOpen} />
        {/* Main content */}
        {showLoading ? (
          <div className="flex justify-center w-full pt-20">
            <Lottie animationData={loading} className="h-40" />
          </div>
        ) : (
          <div className="px-10 md:px-0 md:ml-[14vw] lg:ml-[8vw] mt-8 md:w-3/5 overflow-none relative pb-10">
            <div className="container mx-auto">
              <h1 className="text-3xl font-semibold mb-4 mt-4 text-addedLightblue">
                Sellers.json and Ads.txt Crawler
              </h1>
              {/* SellersJsonForm component for input */}
              <SellersJsonForm
                onResults={handleResults}
                onProgress={handleProgress}
                onManualLines={handleManualLines}
                resetCrawlerState={resetCrawlerState}
              />
              {/* Display progress if available */}
              {progress && <ProcessDisplay progress={progress} />}
              {/* Display results */}
              <ResultsDisplay
                results={results}
                isCrawling={isCrawling}
                manualLines={manualLines}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Crawler;
