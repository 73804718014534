// Fetch data from Added based on the date range and advertisers/marketplaces

export const FetchDataFromAdded = async (
  startDate,
  endDate,
  advertisersId,
  marketplacesId,
  isSingleDate = false
) => {
  try {
    // Construct params object with only defined values
    const params = new URLSearchParams();
    params.append("startDate", startDate);
    params.append("endDate", endDate);
    params.append("singleDate", isSingleDate);

    if (advertisersId) params.append("advertiser", advertisersId);
    if (marketplacesId) params.append("marketplace", marketplacesId);

    const API_URL = `http://localhost:3060/get-added-data?${params.toString()}`;
    // const API_URL = `https://xqyrwl457n6hzk25vodhcsadey0zvwiu.lambda-url.us-west-2.on.aws/get-added-data?${params.toString()}`;
    console.log(
      `🔁 Fetching from URL from Added ${
        advertisersId ? `(${advertisersId})` : ""
      }${marketplacesId ? `(${marketplacesId})` : ""}`,
      API_URL
    );

    const response = await fetch(API_URL);
    const data = await response.json();
    console.log(
      `✅ API Response from Added ${advertisersId && `(${advertisersId})`}${
        marketplacesId && `(${marketplacesId})`
      }`,
      data
    );

    return {
      data: data.data || [],
    };
  } catch (error) {
    console.error("❌ Error fetching data:", error);
    throw error;
  }
};

export const FetchDataFromDemand = async (
  advertiser,
  startDate,
  endDate,
  isSingleDate
) => {
  try {
    // Check if advertiser has APIs

    if (!advertiser.apis || !advertiser.apis.length) {
      return null;
    }

    // Process each API URL
    const responses = await Promise.all(
      advertiser.apis.map(async (apiUrl) => {
        const params = new URLSearchParams({
          demandName: advertiser.name,
          apiUrl: apiUrl,
          startDate: startDate,
          endDate: endDate,
          singleDate: isSingleDate,
        });

        // const API_URL = `https://xqyrwl457n6hzk25vodhcsadey0zvwiu.lambda-url.us-west-2.on.aws/get-demand-data?${params.toString()}`;
        const API_URL = `http://localhost:3060/get-demand-data?${params.toString()}`;
        console.log(`🔁 Fetching from URL from ${advertiser.name}:`, API_URL);

        const response = await fetch(API_URL);
        const data = await response.json();
        console.log(`✅ API Response from ${advertiser.name}:`, data);
        return data;
      })
    );

    // Aggregate the responses
    const aggregatedResponse = responses.reduce((acc, curr) => {
      if (!acc) {
        console.log("🔄 No previous data, returning current data");
        return curr;
      }
      console.log("🔄 Aggregated response:", acc);

      // For single day aggregated data
      if (!isSingleDate && startDate === endDate) {
        console.log("🔄 Single day aggregated data");
        return {
          revenue: (acc.revenue || 0) + (curr.revenue || 0),
          impressions: (acc.impressions || 0) + (curr.impressions || 0),
        };
      }

      // For hourly/daily data
      const mergedData = [...(acc.data || [])];
      console.log("🔄 Merged data:", mergedData);

      (curr.data || []).forEach((currItem) => {
        const timeKey = isSingleDate ? "hour" : "date";
        const existingIndex = mergedData.findIndex(
          (item) => item[timeKey] === currItem[timeKey]
        );

        if (existingIndex === -1) {
          mergedData.push(currItem);
        } else {
          mergedData[existingIndex] = {
            ...mergedData[existingIndex],
            revenue:
              (mergedData[existingIndex].revenue || 0) +
              (currItem.revenue || 0),
            impressions:
              (mergedData[existingIndex].impressions || 0) +
              (currItem.impressions || 0),
          };
        }
      });

      return { data: mergedData };
    }, null);

    return aggregatedResponse;
  } catch (error) {
    console.error("❌ Error fetching data:", error);
    throw error;
  }
};
