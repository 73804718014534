import { useNavigate } from "react-router";
import { useAuth } from "../auth/AuthContext";
import { getAuth, signOut } from "firebase/auth";
import Logo from "../assets/images/logo.png";

// Navbar component to display the navigation bar
export const Navbar = ({ toggleSidebar }) => {
  
  // Get the current user from the authentication context
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // Function to handle user sign out
  const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <>
      <div className="antialiased bg-gray-50 dark:bg-gray-900">
        <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 shadow-md">
          <div className="flex flex-wrap justify-between items-center">
            <div className="flex justify-start items-center">
              {/* Sidebar toggle button for mobile view */}
              <button
                onClick={toggleSidebar}
                className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <svg
                  aria-hidden="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  aria-hidden="true"
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Toggle sidebar</span>
              </button>
              {/* Logo and Home link */}
              <a
                href="/"
                className="flex items-center justify-between mr-4 cursor-pointer"
              >
                <img
                  src={Logo}
                  className="mr-3 ml-1 h-8 md:h-10"
                  alt="Added Logo"
                />
              </a>
            </div>
            {/* Sign Out button for authenticated users */}
            {currentUser && (
              <button
                onClick={handleSignOut}
                className="text-addedLightblue hover:text-blue-800 font-semibold text-xs mr-4"
              >
                Sign Out
              </button>
            )}
          </div>
        </nav>
      </div>
    </>
  );
};
