import { Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./assets/css/index.css";
import bgImg from "./assets/images/bg.webp";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

// Component Imports
import { NotFound } from "./components/404/NotFound";
import { Login } from "./components/login/Login";
import { Crawler } from "./components/crawler/Crawler";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import { SellersJsonManager } from "./components/sellers/SellersJsonManager";
import { DiscrepancyCheck } from "./components/discrepancy/DiscrepancyCheck";

// RoutesManager component for managing application routes
const RoutesManager = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user); // setUser to null if no user, otherwise set to user object
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  // Background Styles
  const bgStyle = {
    backgroundImage: `url(${"https://added.tv/wp-content/uploads/2024/10/bg-scaled.webp"})`,
    backgroundSize: "cover",
    backgroundRepeat: "repeat-y",
    backgroundAttachment: "fixed",
    minHeight: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  };
  const contentStyle = {
    position: "relative",
    minHeight: "100vh",
    zIndex: 1,
    overflow: "auto",
  };

  return (
    <>
      {" "}
      <div style={bgStyle}></div>
      <div style={contentStyle}>
        <Routes>
          {/* Home/Login Route */}
          <Route exact path="/" element={<Login />} />

          {/* Sellers Route wrapped in ProtectedRoute for authentication */}
          <Route
            path="/sellers"
            element={
              <ProtectedRoute>
                <SellersJsonManager />
              </ProtectedRoute>
            }
          />

          {/* Sellers Route wrapped in ProtectedRoute for authentication */}
          <Route
            path="/discrepancy"
            element={
              <ProtectedRoute>
                <DiscrepancyCheck />
              </ProtectedRoute>
            }
          />

          {/* Crawler Route wrapped in ProtectedRoute for authentication */}
          <Route
            path="/crawler"
            element={
              <ProtectedRoute>
                <Crawler />
              </ProtectedRoute>
            }
          />

          {/* 404 Page Route for any unmatched paths */}
          <Route path="/*"   
                element={
                  <ProtectedRoute>
                    <NotFound />
                  </ProtectedRoute>
                } />
        </Routes>
      </div>
    </>
  );
};

export default RoutesManager;
