import React from "react";
import Lottie from 'lottie-react';

const RestoreBackupModal = ({
  showRestoreModal,
  isLoading,
  error,
  backupsData,
  loading,
  selectedBackup,
  setSelectedBackup,
  handleCopyBackupClicked,
  handleRestoreConfirm,
  setShowRestoreModal,
  formatBackupDate,

}) => {
  if (!showRestoreModal) return null; // If the modal shouldn't be displayed, return null.
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center px-4 sm:px-0">
      <div className="bg-white p-5 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-xl mb-4 font-bold text-blue-600">
          Restore From Backup
        </h2>
        {isLoading ? (
          <Lottie animationData={loading} className="h-40" />
        ) : error ? (
          <p>error</p>
        ) : (
          <>
            <p className="mb-2">Please choose a backup to restore from:</p>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={selectedBackup?.id || ""}
              onChange={(e) => {
                const selectedBackup = backupsData.find(
                  (backup) => backup.id === e.target.value
                );
                setSelectedBackup(selectedBackup);
              }}
            >
              <option value="" disabled>
                Select a Backup
              </option>
              {backupsData.slice(-5, -1).map((backup) => (
                <option key={backup.id} value={backup.id}>
                  {formatBackupDate(backup.id)} ({backup.sellers.length}{" "}
                  sellers)
                </option>
              ))}
            </select>
          </>
        )}
        {selectedBackup?.id && (
          <div className="mt-2">
            <div className="flex justify-between items-center">
              <div className="text-xs">Preview:</div>
              <button
                className="text-xs bg-gray-100 px-1 py-1 rounded-lg flex flex-row hover:bg-gray-200"
                onClick={handleCopyBackupClicked}
              >
                <svg
                  className="w-4 h-4 text-gray-800"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                    clipRule="evenodd"
                  />
                </svg>
                Copy All
              </button>
            </div>

            <div className="bg-gray-300 rounded p-2 whitespace-pre-wrap text-xs mt-1 max-h-[300px] overflow-y-auto">
              {selectedBackup?.sellers && selectedBackup.sellers.length > 0 ? (
                <pre>{JSON.stringify(selectedBackup.sellers, null, 2)}</pre>
              ) : (
                "No sellers available"
              )}
            </div>
          </div>
        )}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => setShowRestoreModal(false)}
            className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleRestoreConfirm}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            disabled={isLoading || !selectedBackup}
          >
            Restore
          </button>
        </div>
      </div>
    </div>
  );
};

export default RestoreBackupModal;
