import { useEffect, useState } from "react";
import loading from "../../../assets/css/loading.json";
import Lottie from "lottie-react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

const NewAdvertiserModal = ({ isOpen, onClose, onSubmit, editData = null }) => {
  const [formData, setFormData] = useState({
    name: editData?.name || "",
    method: editData?.method || "API-Direct",
    apis: editData?.apis || [""],
    idType: editData?.idType || "advertiser",
    ids: editData?.ids || [""],
    last_modified: editData?.last_modified || new Date().toISOString(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditing] = useState(!!editData);

  const formatDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const submitData = {
      ...formData,
      last_modified: formatDate(new Date()),
      apis: formData.apis.filter((url) => url.trim() !== ""),
    };

    if (editData?.id) {
      submitData.id = editData.id;
    }

    onSubmit(submitData);
    onClose();
  };

  const addNewUrl = () => {
    setFormData((prev) => ({
      ...prev,
      apis: [...prev.apis, ""],
    }));
  };

  const removeUrl = (index) => {
    setFormData((prev) => ({
      ...prev,
      apis: prev.apis.filter((_, i) => i !== index),
    }));
  };

  const handleUrlChange = (index, value) => {
    setFormData((prev) => ({
      ...prev,
      apis: prev.apis.map((url, i) => (i === index ? value : url)),
    }));
    setErrors({ ...errors, apis: "" });
  };

  const handleIdChange = (index, value) => {
    setFormData((prev) => ({
      ...prev,
      ids: prev.ids.map((id, i) => (i === index ? value : id)),
    }));
  };

  const addNewId = () => {
    setFormData((prev) => ({
      ...prev,
      ids: [...prev.ids, ""],
    }));
  };

  const removeId = (index) => {
    setFormData((prev) => ({
      ...prev,
      ids: prev.ids.filter((_, i) => i !== index),
    }));
  };

  // Background Styles for the modal
  const bgStyle = {
    backgroundImage: `url(${"https://added.tv/wp-content/uploads/2024/10/bg-scaled.webp"})`,
    backgroundSize: "cover",
    backgroundRepeat: "repeat-y",
    zIndex: -1,
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center px-4 sm-px-0">
      <div
        className="bg-white p-5 rounded-lg shadow-xl max-w-md w-full"
        style={bgStyle}
      >
        <h2 className="text-xl mb-4 font-bold text-addedBlue">
          {isEditing ? `Edit Advertiser - ${editData.name}` : "New Advertiser"}
        </h2>
        {isLoading ? (
          <Lottie animationData={loading} className="h-40" />
        ) : (
          <>
            <div className="mb-4 pb-3">
              <div className="flex flex-col text-left w-full space-y-2">
                {/* Name field */}
                <div id="advertiser_name">
                  <label className="w-1/4 text-left mr-2 font-semibold text-sm whitespace-nowrap">
                    Name:
                  </label>
                  <div className="w-3/4">
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          name: e.target.value,
                        });
                        setErrors({ ...errors, name: "" });
                      }}
                      placeholder="Type in Advertiser Name"
                      className={`w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border-gray-300`}
                    />
                    {errors.name && (
                      <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                    )}
                  </div>
                </div>

                {/* Method field */}
                <div id="method">
                  <label className="w-1/4 text-left mr-2 font-semibold text-sm whitespace-nowrap">
                    Method:
                  </label>
                  <div className="w-3/4">
                    <select
                      value={formData.method}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          method: e.target.value,
                        });
                        setErrors({ ...errors, method: "" });
                      }}
                      className={`w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border-gray-300`}
                    >
                      <option value="API-Direct">API-Direct</option>
                      <option value="API-Manual">API-Manual</option>
                      <option value="Scrape">Scrape</option>
                    </select>
                    {errors.method && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.method}
                      </p>
                    )}
                  </div>
                </div>

                {/* ID Type Selection */}
                <div id="id_type">
                  <label className="w-1/4 text-left mr-2 font-semibold text-sm whitespace-nowrap">
                    Data from:
                  </label>
                  <div className="w-3/4 flex gap-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        value="advertiser"
                        checked={formData.idType === "advertiser"}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            idType: e.target.value,
                          })
                        }
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <span className="ml-2 text-sm">Advertiser ID</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        value="marketplace"
                        checked={formData.idType === "marketplace"}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            idType: e.target.value,
                          })
                        }
                        className="form-radio h-4 w-4 text-blue-600"
                      />
                      <span className="ml-2 text-sm">Marketplace ID</span>
                    </label>
                  </div>
                </div>

                {/* IDs Section */}
                <div id="ids">
                  <label className="w-1/4 text-left mr-2 font-semibold text-xs whitespace-nowrap">
                    {formData.idType === "marketplace"
                      ? "Marketplace IDs:"
                      : "Advertiser IDs:"}
                  </label>
                  <div className="w-3/4 space-y-2">
                    {formData.ids.map((id, index) => (
                      <div key={index} className="flex gap-2">
                        <input
                          type="text"
                          value={id}
                          onChange={(e) =>
                            handleIdChange(index, e.target.value)
                          }
                          placeholder={`Enter ${formData.idType} ID`}
                          className="flex-1 px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border-gray-300"
                        />
                        {formData.ids.length > 1 && (
                          <button
                            type="button"
                            onClick={() => removeId(index)}
                            className="p-2 text-red-500 hover:text-red-700"
                          >
                            <XMarkIcon className="h-5 w-5" />
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={addNewId}
                      className="flex items-center gap-1 text-blue-500 hover:text-blue-700 text-sm"
                    >
                      <PlusIcon className="h-4 w-4" />
                      Add ID
                    </button>
                  </div>
                </div>

                {/* API URLs section */}
                {formData.method === "API-Direct" && (
                  <div id="apis">
                    <label className="w-1/4 text-left mr-2 font-semibold text-sm whitespace-nowrap">
                      API URLs:
                    </label>
                    <p className="text-xs text-gray-500 -mt-1 mb-1">
                      Make sure to use YYYY-MM-DD instead of actual dates
                    </p>
                    <div className="w-3/4 space-y-2">
                      {formData.apis.map((url, index) => (
                        <div key={index} className="flex gap-2">
                          <input
                            type="text"
                            value={url}
                            onChange={(e) =>
                              handleUrlChange(index, e.target.value)
                            }
                            placeholder="Enter API URL"
                            className="flex-1 px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border-gray-300"
                          />
                          {formData.apis.length > 1 && (
                            <button
                              type="button"
                              onClick={() => removeUrl(index)}
                              className="p-2 text-red-500 hover:text-red-700"
                            >
                              <XMarkIcon className="h-5 w-5" />
                            </button>
                          )}
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={addNewUrl}
                        className="flex items-center gap-1 text-blue-500 hover:text-blue-700 text-sm"
                      >
                        <PlusIcon className="h-4 w-4" />
                        Add URL
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* You might want to add a last modified display for editing */}
            {isEditing && (
              <div className="text-sm text-gray-500 mb-4">
                Last Modified: {formatDate(formData.last_modified)}
              </div>
            )}

            {/* Action buttons */}
            <div className="flex justify-end">
              <button
                onClick={() => onClose()}
                className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
              >
                {isEditing ? "Update" : "Create"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewAdvertiserModal;
