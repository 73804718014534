import React, { useState, useEffect, useMemo, useCallback } from "react";

// Component to display the results of the crawling process
const ResultsDisplay = ({ results, isCrawling, manualLines }) => {
  const [filter, setFilter] = useState("All");
  const [searchedQuery, setSearchedQuery] = useState(null);
  const [showFoundLinesMap, setShowFoundLinesMap] = useState({});
  const [savedResultCounts, setSavedResultCounts] = useState({
    All: 0,
    "✅ Done": 0,
    "⚠️ Partially": 0,
    "🛑 No Lines": 0,
    "❌ Not found": 0,
  });
  // Reset filter to "All" when crawling is finished
  useEffect(() => {
    if (!isCrawling) {
      setFilter("All");
    }
  }, [isCrawling]);

  // Get the status color based on the result
  const getStatusColor = (result) => {
    if (
      !result.adsTxtFound ||
      (result.adsTxtFound &&
        result.predefinedLines.length === 0 &&
        !result.sellerIdMatch)
    ) {
      return "red";
    }
    if (
      result.predefinedLines.length === manualLines.length &&
      result.sellerIdMatch
    ) {
      return "green";
    }
    return "yellow";
  };

  // Get the button color based on the option and its active state
  const getButtonColor = (option, isActive) => {
    if (!isActive) return "bg-gray-200 text-gray-700";
    switch (option) {
      case "✅ Done":
        return "bg-green-200 text-green-800";
      case "⚠️ Partially":
        return "bg-yellow-200 text-yellow-800";
      case "🛑 No Lines":
        return "bg-red-200 text-red-800";
      case "❌ Not found":
        return "bg-red-300 text-red-900";
      default:
        return "bg-blue-500 text-white";
    }
  };

  const getResultLabel = (result) => {
    if (!result.adsTxtFound) return "❌ Not found";
    if (result.error) return `❌ Error - ${result.error.message}`;
    if (
      result.predefinedLines.length === manualLines.length &&
      (result.sellerIdMatch || !result.seller)
    )
      return "✅ Done";
    if (
      (result.predefinedLines.length > 0 &&
        result.predefinedLines.length < manualLines.length) ||
      (result.adsTxtFound &&
        !result.sellerIdMatch &&
        result.predefinedLines.length === manualLines.length)
    )
      return "⚠️ Partially";
    if (result.predefinedLines.length === 0) return "🛑 No Lines";
    return "❌ else";
  };

  // Modify the filteredResults to include search filtering
  const filteredResults = useMemo(() => {
    return results.filter((result) => {
      let includeResult = true;

      // Filter based on the selected filter
      switch (filter) {
        case "✅ Done":
          includeResult =
            result.adsTxtFound &&
            !result.error &&
            result.predefinedLines.length === manualLines.length &&
            (result.sellerIdMatch || !result.seller); // Consider it done if there's no seller (direct URL)
          break;
        case "⚠️ Partially":
          includeResult =
            (result.adsTxtFound &&
              !result.error &&
              result.predefinedLines.length > 0 &&
              result.predefinedLines.length < manualLines.length) ||
            (result.adsTxtFound &&
              result.sellerIdMatch &&
              result.predefinedLines.length !== manualLines.length);
          break;
        case "🛑 No Lines":
          includeResult =
            result.adsTxtFound &&
            !result.error &&
            result.predefinedLines.length === 0 &&
            (!result.sellerIdMatch || !result.seller); // Consider it "No Lines" if there's no seller (direct URL)
          break;
        case "❌ Not found":
          includeResult = !result.adsTxtFound || !!result.error;
          break;
        default:
          break;
      }

      // Filter based on the search query
      if (searchedQuery) {
        const searchDomain = result.seller
          ? result.seller.domain
          : result.domain;
        includeResult =
          includeResult &&
          searchDomain.toLowerCase().includes(searchedQuery.toLowerCase());
      }

      return includeResult;
    });
  }, [results, filter, searchedQuery, manualLines.length]);

  // Get the result label based on the result
  const calculateResultCounts = useCallback(() => {
    const counts = {
      All: results.length,
      "✅ Done": 0,
      "⚠️ Partially": 0,
      "🛑 No Lines": 0,
      "❌ Not found": 0,
    };

    results.forEach((result) => {
      const label = getResultLabel(result);
      counts[label]++;
    });

    // Apply the filtering logic to the individual counts

    return counts;
  }, [results, filteredResults, filter, getResultLabel]);

  const resultCounts = useMemo(
    () => calculateResultCounts(),
    [calculateResultCounts]
  );

  const handleSearchInputChange = (e) => {
    setSearchedQuery(e.target.value);
    console.log(searchedQuery);
  };

  const handleCopyResults = () => {
    // Copy only the domain
    const domains = filteredResults.map((result) => result.domain);
    navigator.clipboard.writeText(domains.join("\n"));
    alert("Copied to clipboard");
  };

  return (
    <div className="mt-4">
      {results.length > 0 && (
        <div className="flex flex-row justify-between items-center">
          <h2 className="text-2xl font-bold flex-grow">Results</h2>
          <form className="w-1/3">
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only"
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                onChange={handleSearchInputChange}
                className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                placeholder="Search for a Seller"
                required
              />
            </div>
          </form>
        </div>
      )}
      <div className="flex justify-between items-center mb-4 mt-2">
        {results.length > 0 && (
          <div className="flex flex-row justify-between items-center w-full">
            <div className="space-x-1">
              {[
                "All",
                "✅ Done",
                "⚠️ Partially",
                "🛑 No Lines",
                "❌ Not found",
              ].map((option) => (
                <button
                  key={option}
                  onClick={() => setFilter(option)}
                  className={`px-3 py-1 rounded select-none ${getButtonColor(
                    option,
                    filter === option
                  )}`}
                >
                  {option} {`(${resultCounts[option]})`}
                </button>
              ))}
            </div>
            <button
              className="bg-addedLightblue text-white py-1 px-2 rounded"
              onClick={handleCopyResults}
            >
              Copy
            </button>
          </div>
        )}
      </div>
      <div className="space-y-4">
        {filteredResults.map((result, index) => (
          <div
            key={index}
            className={`bg-white p-4 rounded-lg shadow border-4 border-${getStatusColor(
              result
            )}-300`}
          >
            <div className="flex items-center">
              <h3 className="text-lg font-semibold">
                {result.seller ? result.seller.domain : result.domain}
              </h3>
              <span
                className={`px-2 py-1 rounded ml-2 select-none ${getButtonColor(
                  getResultLabel(result),
                  true
                )}`}
              >
                {getResultLabel(result)}
              </span>
              {getResultLabel(result) !== "❌ Not found" && (
                <a
                  href={result.url}
                  target="_blank"
                  className="px-2 py-1 rounded ml-2 bg-blue-100 text-addedBlue cursor-pointer select-none	"
                >
                  → To File
                </a>
              )}
            </div>
            {result && (
              <p className="text-sm text-gray-600">
                <span className="font-bold">{index + 1}</span> - Seller ID:{" "}
                {result.seller ? result.seller.seller_id : result.seller_id}
              </p>
            )}
            <div className="mt-2 space-y-2 space-x-2">
              <div
                className={`inline-block px-2 py-1 rounded select-none	 ${
                  result.adsTxtFound
                    ? "bg-green-200 text-green-800"
                    : "bg-red-200 text-red-800"
                }`}
              >
                {result.adsTxtFound
                  ? result.fileType === "ads.txt"
                    ? "✅ Ads.txt found"
                    : "✅ App-ads.txt found"
                  : "❌ Ads/App-Ads.txt not found"}
              </div>
              {result.adsTxtFound && (
                <>
                  <div
                    className={`inline-block px-2 py-1 rounded select-none	 ${
                      result.sellerIdMatch
                        ? "bg-green-200 text-green-800"
                        : "bg-red-200 text-red-800"
                    }`}
                  >
                    {result.sellerIdMatch
                      ? "✅ Seller ID match"
                      : "❌ Seller ID not match"}
                  </div>
                  <div className="font-semibold flex items-center space-x-2">
                    <p>
                      Predefined lines found: {result.predefinedLines.length}/
                      {manualLines.length}
                    </p>
                    {result.predefinedLines.length > 0 && (
                      <button
                        onClick={() =>
                          setShowFoundLinesMap((prev) => ({
                            ...prev,
                            [index]: !prev[index],
                          }))
                        }
                        className="bg-gray-200 hover:bg-gray-300 text-gray-800 py-1 px-2 rounded text-xs"
                      >
                        {showFoundLinesMap[index] ? "Hide" : "Show"}
                      </button>
                    )}
                  </div>
                  {showFoundLinesMap[index] && (
                    <div>
                      <p className="font-semibold mt-2">Found lines:</p>
                      <ol className="list-decimal list-inside text-green-600">
                        {result.predefinedLines.map((line, lineIndex) => (
                          <li key={lineIndex}>{line}</li>
                        ))}
                      </ol>
                    </div>
                  )}
                  {result.missingLines.length > 0 && (
                    <div>
                      <p className="font-semibold">Missing lines:</p>
                      <ol className="list-decimal list-inside text-red-600">
                        {result.missingLines.map((line, lineIndex) => (
                          <li key={lineIndex}>{line}</li>
                        ))}
                      </ol>
                    </div>
                  )}
                </>
              )}
            </div>
            {!result.adsTxtFound && result.error && (
              <p className="mt-2 text-red-600">
                {result.error.includes("ENOTFOUND") ||
                result.error.includes("404")
                  ? "Error: Files not found (404)"
                  : result.error.includes("403")
                  ? "Error: Couldn't access files (403)"
                  : result.error.includes("ECONNREFUSED")
                  ? "Error: Connection refused (503)"
                  : result.error.includes("before secure TLS") ||
                    result.error.includes("certificate has expired")
                  ? "Error: Seller's server has SSL security issues."
                  : result.error}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultsDisplay;
