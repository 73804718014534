// Function to fetch sellers.json data
async function fetchSellersJson(domain) {
  // PRODUCTION URL
  const apiUrl = `https://zeosuhyih7xpvwvwbd3qa3ixrq0qrmly.lambda-url.eu-central-1.on.aws/sellers?url=${domain}`;

  // Testing URL
  // const apiUrl = `http://localhost:3700/sellers?url=${domain}`;
  try {
    // Sending the request with JSON headers
    const response = await fetch(apiUrl, {
      cache: "no-store",
      headers: {
        Accept: "application/json",
      },
    });

    console.log(response);
    // If response has an error return error
    if (!response.ok) {
      const text = await response.text();
      console.error("❌ Error with fetching sellers.json:", text);
      throw new Error(`${response.statusText}`);
    }

    // Making sure we got a JSON back
    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      const text = await response.text();
      console.error("Unexpected content type:", contentType);
      console.error("Response text:", text);
      throw new Error(`Expected JSON, but got ${contentType}`);
    }

    // Gettings the response
    const responseText = await response.text();

    // Parsing the JSON response and returning it
    try {
      const sellers = JSON.parse(responseText);
      return sellers;
    } catch (error) {
      // Handling errors
      console.error("❌ Failed to parse JSON:", error);
      throw new Error("❌ Failed to parse JSON");
    }
  } catch (error) {
    console.error("❌ Fetch error:", error);
    throw error;
  }
}

// Function to fetch ads.txt data for a given domain
async function fetchAdsTxt(domain, type) {
  // console.log(`🔄 Fetching data from ${domain}`);

  const apiUrl =
    type === "byUrl"
      ? `https://zeosuhyih7xpvwvwbd3qa3ixrq0qrmly.lambda-url.eu-central-1.on.aws/fetch-ads-txt-by-url`
      : `https://zeosuhyih7xpvwvwbd3qa3ixrq0qrmly.lambda-url.eu-central-1.on.aws/fetch-ads-txt`;

  // const apiUrl =
  //   type === "byUrl"
  //     ? `http://localhost:3700/fetch-ads-txt-by-url`
  //     : `http://localhost:3700/fetch-ads-txt`;

  const fetchWithTimeout = (url, options, timeout = 42000) => {
    return Promise.race([
      fetch(url, options),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Request timed out")), timeout)
      ),
    ]);
  };

  try {
    const response = await fetchWithTimeout(
      `${apiUrl}?${type === "byUrl" ? "url" : "domain"}=${encodeURIComponent(
        domain
      )}`,
      {
        cache: "no-store",
      }
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error("Error response:", errorResponse);
      return {
        error: errorResponse.error,
        details: errorResponse.details,
      };
    }
    const jsonResponse = await response.json();
  
    return { content: jsonResponse.content, type: jsonResponse.type, url: jsonResponse.url };
  } catch (error) {
    console.error(`❌ Error fetching data from ${domain}/ads.txt:`, error);
    return {
      error: `Failed to fetch ads.txt for ${domain}: ${error.message}`,
      details: error.stack, // Optional: you can include the stack trace if needed
    };
  }
}

// Function to check ads.txt content for matches with predefined lines and seller ID
function checkAdsTxt(
  adsTxtContent,
  domain,
  sellerId,
  manualLines,
  searchingDomain
) {
  console.log(
    `enterting checkAdsTxt with domain: ${domain}, sellerId: ${sellerId}, manualLines: ${manualLines}, searchingdomain: ${searchingDomain}`
  );
  const results = {
    predefinedLines: [],
    sellerIdMatch: null,
    missingLines: [],
  };
  const lines = adsTxtContent.split("\n");
  const foundLines = new Set();

  for (const line of lines) {
    const trimmedLine = line.trim().toLowerCase();

    for (const predefinedLine of manualLines) {
      const predefinedParts = predefinedLine
        .trim()
        .toLowerCase()
        .split(",")
        .map((part) => part.trim());
      const lineParts = trimmedLine.split(",").map((part) => part.trim());

      if (
        predefinedParts.every(
          (part, index) => lineParts[index] && lineParts[index].startsWith(part)
        )
      ) {
        foundLines.add(predefinedLine);
      }
    }
    // console.log("----------------------------------------------------------------")
    // console.log(domain)
    // console.log(sellerId)
    // console.log("----------------------------------------------------------------")

    // Check for seller ID match
    if (domain && sellerId) {
      // console.log("----------------------------------------------------------------")
      // console.log(domain)

      // const sellerIdPattern = new RegExp(`Added\\.tv,\\s*${sellerId}\\s*,\\s*DIRECT`,"i");
      const sellerIdPattern = new RegExp(
        `${searchingDomain},\\s*${sellerId}\\s*,\\s*DIRECT`,
        "i"
      );
      // console.log(sellerIdPattern)
      // console.log(line)
      // console.log("----------------------------------------------------------------")
      // console.log(sellerIdPattern)
      // console.log(line)
      // console.log(line.trim)
      if (sellerIdPattern.test(line)) {
        console.log(` Seller ID match found: ${line}`);
        results.sellerIdMatch = line.trim();
      }
    }
  }

  results.predefinedLines = Array.from(foundLines);
  results.missingLines = manualLines.filter((line) => !foundLines.has(line));

  return results;
}

// Function to extract the domain
function extractDomain(url) {
  try {
    const parsedUrl = new URL(url.startsWith("http") ? url : `http://${url}`);
    return parsedUrl.hostname;
  } catch (error) {
    // If parsing fails, try a simple regex approach
    const match = url.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/
    );
    return match ? match[1] : url;
  }
}

// Stop Crawling Boolean Check
let stopCrawling = false;

// Function to stop the crawling process
export function stopCrawlingProcess() {
  stopCrawling = true;
  window.location.reload()
}

// Main function to crawl sellers.json and process ads.txt files
export async function crawlSellersJson(
  progressCallback,
  resultCallback,
  manualLines,
  domain,
  searchSpecificUrls,
  specificUrls
) {
  stopCrawling = false;
  const results = [];
  let processedCount = -1;
  const searchingDomain = extractDomain(domain);
  // console.log("---------------Specific URLS-----------------")
  // console.log(searchSpecificUrls)
  // console.log(specificUrls)
  // console.log("--------------------------------")
  try {
    // Always fetch sellers.json
    progressCallback({ status: "fetching", message: "Fetching sellers.json" });
    const sellers = await fetchSellersJson(domain);
    progressCallback({
      status: "fetched",
      message: "Sellers.json fetched successfully",
    });

    // Create a map of domain to seller_id
    const sellersMap = sellers.reduce((acc, seller) => {
      acc[seller.domain] = seller.seller_id;
      return acc;
    }, {});
    let matchingUrls = {};

    if (searchSpecificUrls) {
      console.log("--------------------------------");
      console.log(specificUrls);
      const updatedSpecificUrls = specificUrls.map((url) => {
        return url.split("/")[0];
      });
      console.log(updatedSpecificUrls);
      console.log(sellersMap);
      matchingUrls = Object.fromEntries(
        updatedSpecificUrls
          .filter((url) => url in sellersMap)
          .map((url) => [url, sellersMap[url]])
      );
      console.log(matchingUrls);
      console.log("--------------------------------");
    }

    const domainsToCrawl = searchSpecificUrls
      ? specificUrls
      : Object.keys(sellersMap);

    for (const currentDomain of domainsToCrawl) {
      if (stopCrawling) {
        progressCallback({
          status: "stopped",
          message: "Crawling stopped by user",
        });
        break;
      }

      processedCount++;
      progressCallback({
        status: "processing",
        message: `Processing ${currentDomain} (${processedCount + 1}/${
          domainsToCrawl.length
        })`,
      });

      try {
        const type = searchSpecificUrls === true ? "byUrl" : "";
        const adsTxtResult = await fetchAdsTxt(currentDomain, type);
        const extractedDomain = extractDomain(currentDomain);
        const sellerId = sellersMap[currentDomain];
        const matchingurlsellerid = searchSpecificUrls
        ? (matchingUrls[extractedDomain] || "Couldn't get seller id, Domain may not exist in sellers.json")
        : (sellerId || "Couldn't get seller id, Domain may not exist in sellers.json");
        console.log("000000000");
        console.log(sellerId);
        console.log(matchingurlsellerid);
        console.log("000000000");
        if (adsTxtResult.error) {
          console.log(`error with ${currentDomain}, seller id is ${sellerId}`);
          const result = {
            domain: currentDomain,
            adsTxtFound: false,
            seller: matchingurlsellerid
              ? { domain: extractedDomain, seller_id: matchingurlsellerid }
              : "not found",
            error: adsTxtResult.error,
          };
          console.log(result);
          results.push(result);
          resultCallback(result);
          continue;
        }
        // console.log(sellerId)
        console.log("Current Domain:  " + currentDomain);
        console.log("Extracted Domain: " + extractedDomain);
        const checkResults = checkAdsTxt(
          adsTxtResult.content,
          extractedDomain,
          searchSpecificUrls ? matchingurlsellerid : sellerId,
          manualLines,
          searchingDomain
        );
        const result = {
          domain: currentDomain,
          seller: matchingurlsellerid
            ? { domain: extractedDomain, seller_id: matchingurlsellerid }
            : "not found",
          adsTxtFound: true,
          fileType: adsTxtResult.type,
          isSpecificURLMethod: searchSpecificUrls,
          content: adsTxtResult.content,
          url: adsTxtResult.url,
          ...checkResults,
        };

        if (!sellerId) {
          result.sellerIdNote = "Couldn't get seller_id from sellers.json";
        }

        results.push(result);
        resultCallback(result);
      } catch (error) {
        console.error(`Error processing ${currentDomain}:`, error);

        const result = {
          domain: currentDomain,
          seller: sellersMap[currentDomain]
            ? { domain: currentDomain, seller_id: sellersMap[currentDomain] }
            : null,
          seller_id: "Error",
          adsTxtFound: false,
          error: error.message,
        };
        results.push(result);
        resultCallback(result);
      }
    }

    progressCallback({ status: "completed", message: "Crawling completed" });
    return results;
  } catch (error) {
    processedCount++;
    progressCallback({
      status: "error",
      message: `Error: ${error.message} (Issue with Crawler server)`,
    });
    throw new Error(`Error: ${error.message}`);
  }
}
