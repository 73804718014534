import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

// ProtectedRoute component to guard routes that require authentication
export const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth(); // Get the current user from the authentication context

  // If the user is not authenticated, navigate to the login page
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // If the user is authenticated, render the children components
  return children;
};

export default ProtectedRoute;
