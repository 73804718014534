// Neccessary Imports
import React, { useState } from "react";
import { crawlSellersJson, stopCrawlingProcess } from "./crawlerScript";

// Form component to handle input and submission for crawling sellers.json
const SellersJsonForm = ({
  onResults,
  onProgress,
  onManualLines,
  resetCrawlerState,
}) => {
  const [loading, setLoading] = useState(false); // State to manage loading status
  const [manualLines, setManualLines] = useState(""); // State to manage user input lines
  const [isCollapsed, setIsCollapsed] = useState(false); // State to manage collapsed state of the input area
  const [isStopping, setIsStopping] = useState(false);
  const [isChangingDomain, setIsChangingDomain] = useState(true);
  const [changedDomainInput, setChangedDomainInput] = useState(
    "added.tv/sellers.json"
  );
  const [searchingDomain, setSearchingDomain] = useState(
    "added.tv/sellers.json"
  );
  const [showSpecificUrlsDivs, setShowSpecificUrlsDivs] = useState(true);
  const [searchSpecificUrls, setSearchSpecificUrls] = useState(false);
  const [specificUrls, setSpecificUrls] = useState("");

  const handleChangedDomainInput = (e) => {
    setChangedDomainInput(e.target.value);
  };
  const formatDomain = (domain) => {
    return domain
      .replace(/^https?:\/\//, "") // Remove http:// or https://
      .replace(/^www\./, "") // Remove www.
      .replace(/\/$/, ""); // Remove trailing slash
  };

  const handleChangedDomainSubmit = () => {
    const formattedDomain = formatDomain(changedDomainInput);
    setSearchingDomain(formattedDomain);
    setIsChangingDomain(false);
  };
  const toggleIsChangingDomain = () => {
    setIsChangingDomain(!isChangingDomain);
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    resetCrawlerState();
    setLoading(true);
    setIsCollapsed(true);
    setIsStopping(false);
    setShowSpecificUrlsDivs(false);

    try {
      const lines = manualLines
        .split("\n")
        .filter((line) => line.trim() !== "");
        console.log(lines)
      const urls = searchSpecificUrls
        ? specificUrls.split("\n").filter((url) => url.trim() !== "").map(line => line.replace(/^(https?:\/\/)?(www\.)?/, ''))
        : [];
      console.log(urls);
      onManualLines(lines);
      console.log(searchingDomain)
      await crawlSellersJson(
        onProgress,
        onResults,
        lines,
        searchingDomain,
        searchSpecificUrls,
        urls
      );
    } catch (error) {
      console.log(error);
      return;
    }

    setLoading(false);
    setIsCollapsed(false);
    setIsStopping(false);
    setShowSpecificUrlsDivs(true);
  };

  // Toggle the collapsed state of the input area
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Count the number of non-empty lines
  const nonEmptyLinesCount = manualLines
    .split("\n")
    .filter((line) => line.trim() !== "").length;

  // Determine if the submit button should be disabled
  const isButtonDisabled = loading || !manualLines.trim();

  // Function to stop the crawling process
  const stopCrawling = () => {
    setIsStopping(true);
    setShowSpecificUrlsDivs(true);
    stopCrawlingProcess();
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4">
      <div className="mb-4" style={{ overflow: "hidden" }}>
        <div className="flex justify-between items-center">
          <label
            htmlFor="manualLines"
            className="block text-sm font-medium text-gray-700"
          >
            Enter lines to check (one per line):
          </label>
          {loading && (
            <button
              type="button"
              onClick={toggleCollapse}
              className="text-sm text-blue-500 hover:text-blue-700"
            >
              {isCollapsed ? "Show lines" : "Hide lines"}
            </button>
          )}
        </div>
        <div className="text-xs flex flex-row mb-2">
          Searching for{" "}
          {loading ? (
            <>{searchingDomain}</>
          ) : (
            <>
              {isChangingDomain ? (
                <div>
                  :
                  <input
                    placeholder="Domain.com"
                    className="ml-2 border rounded px-2"
                    required
                    value={changedDomainInput}
                    onChange={handleChangedDomainInput}
                  />
                  <button
                    type="submit"
                    className="font-bold px-2 ml-1 rounded bg-green-500 hover:bg-green-400 text-white"
                    onClick={handleChangedDomainSubmit}
                  >
                    ✔
                  </button>
                </div>
              ) : (
                <div className="ml-1">
                  {}
                  {searchingDomain}
                  <span
                    className="hover:underline text-addedLightblue cursor-pointer ml-1"
                    onClick={toggleIsChangingDomain}
                  >
                    Change?
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        {!isCollapsed && (
          <div
            style={{ minWidth: "50%", maxWidth: "65%", overflow: "visible" }}
          >
            <textarea
              id="manualLines"
              value={manualLines}
              onChange={(e) => setManualLines(e.target.value)}
              placeholder="Enter lines here..."
              disabled={loading}
              style={{
                resize: "both",
                minHeight: "100px",
                height: "150px",
                width: "100%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1)",
                overflow: "auto",
              }}
            />
          </div>
        )}
{showSpecificUrlsDivs &&
        <div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={searchSpecificUrls}
              onChange={(e) => setSearchSpecificUrls(e.target.checked)}
              className="mr-2"
            />
            Search for specific ads.txt URLs
          </label>
        </div>

        {searchSpecificUrls && (
          <div className="mb-4">
            <label
              htmlFor="specificUrls"
              className="block text-sm font-medium text-gray-700"
            >
              Enter specific URLs (one per line):
            </label>
            <textarea
              id="specificUrls"
              value={specificUrls}
              onChange={(e) => setSpecificUrls(e.target.value)}
              placeholder="Enter URLs Here..."
              disabled={loading}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              rows="4"
            />
          </div>
        )}
        </div>
}
        {isCollapsed && (
          <div className="mt-1 text-sm text-gray-500">
            {nonEmptyLinesCount} lines entered
          </div>
        )}
      </div>{" "}
      <button
        type="submit"
        className={`bg-blue-500 font-bold py-2 px-4 rounded ${
          isButtonDisabled
            ? "bg-gray-300 cursor-not-allowed"
            : "bg-addedLightblue hover:bg-indigo-500"
        } ${
          loading ? "text-gray-600 font-light" : "text-white"
        } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-addedLightblue`}
        disabled={isButtonDisabled}
        title={
          isButtonDisabled
            ? "Please enter some lines before crawling"
            : "Start crawling"
        }
      >
        {loading ? "🕷️ Crawling..." : "Start Crawling"}
      </button>
      {/* {loading && <button className="bg-red-500 text-white font-bold py-2 px-4 rounded ml-1" onClick={stopCrawling}>Stop</button>} */}
      {loading && (
        <button
          type="button"
          className="bg-red-500 text-white font-bold py-2 px-4 rounded ml-1"
          onClick={stopCrawling}
          disabled={isStopping}
        >
          {isStopping ? "Stopping..." : "Cancel"}
        </button>
      )}
    </form>
  );
};

export default SellersJsonForm;
