// Files and Module Imports
import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../auth/AuthContext";
import { Navbar } from "../../layout/Navbar";
import Lottie from "lottie-react";
import loading from "../../assets/css/loading.json";
import { Sidebar } from "../../layout/Sidebar";
import bgImg from "../../assets/images/bg.webp";
import { db } from "../../../firebase";
import { serverTimestamp, doc, setDoc } from "firebase/firestore";
import {
  fetchSellers,
  addSeller,
  deleteSeller,
  updateSeller,
  fetchBackups,
  saveBackupSellers,
  deleteAllSellers,
} from "./CrudFunctions";
import toast, { Toaster } from "react-hot-toast";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import RestoreBackupModal from "./RestoreBackupModal";
import { HandleAddSeller } from "./HandleAddSeller";
import { handleBulkUpload } from "./bulkUploadHandler";
import BulkUploadModal from "./BulkUploadModal";

export const SellersJsonManager = (props) => {
  // State Manager: Is Sidebar Open - using this to manage views
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // State Manager: New Seller - using this to then create a new seller
  const [newSeller, setNewSeller] = useState({
    seller_id: "",
    seller_name: "",
    seller_domain: "",
    seller_type: "PUBLISHER",
  });

  // State Manager: Is Editing - Using this to manage UI and the function that creates and updates sellers
  const [isEditing, setIsEditing] = useState(false);

  // State Manager: Token - Using this to validate requests for crud operations
  const [token, setToken] = useState(process.env.REACT_APP_FIREBASE_API_KEY);

  // State Manager: Show/Hide Restore Modal - Using this to open restore from backup modal
  const [showRestoreModal, setShowRestoreModal] = useState(false);

  // State Manager: Store Backups Data - Using this to then load it for user to choose from
  const [backupsData, setBackupsData] = useState([]);

  // State Manager: User Selected Backups Data - Using this to pass the selected backup
  const [selectedBackup, setSelectedBackup] = useState("");

  // State Manager: Show/Hide Is Loading Restore Backup - Using this to manage UI loading state
  const [isLoadingRestoreBackup, setIsLoadingRestoreBackup] = useState(false);

  // Current user variable - using this for validating the current user
  const { currentUser } = useAuth();

  // State Manager: Sellers (For Creating/Updating Seller) - using this to get and update sellers from DB
  const [sellers, setSellers] = useState([]);

  // Effect to get sellers from DB, update table and then hide loading
  useEffect(() => {
    fetchSellersData();
  }, [currentUser]);
  // ----------------------------------------------------------------
  const [error, setError] = useState(null);

  // validate inputs:
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // Check if both seller_domain and seller_id have values
    if (newSeller.seller_domain.length > 0 && newSeller.seller_id.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [newSeller.seller_domain, newSeller.seller_id]);
  // ----------------------------------------------------------------

  // State Manager: Current Seller ID - Using this for avoiding duplications
  const [currentSellerId, setCurrentSellerId] = useState(null);

  // State Manager: Current Seller Name - Using this for avoiding duplications
  const [currentSellerName, setCurrentSellerName] = useState(null);

  // State Manager: Show Loading (Main) - Using this to Show/Hide loading based on current user state
  const [showLoading, setShowLoading] = useState(true);

  // ==========================================================================
  // ==========================================================================
  // |                              Table                                      |
  // ==========================================================================
  // State Manager: Searched Term - Using this for the Search functionality
  const [searchTerm, setSearchTerm] = useState("");

  // State Manager: Is Loading Table - Using this to show/hide loading while table is loading
  const [isLoadingTable, setIsLoadingTable] = useState(true);

  // State Manager: Sorting Method for Table - Default as last modified
  const [sortConfig, setSortConfig] = useState({
    key: "last_modified",
    direction: "decending",
  });
    // For Table Sorting: Sorting Methods states declaration
    const requestSort = (key) => {
      let direction = "ascending";
      if (sortConfig.key === key && sortConfig.direction === "ascending") {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };
  
    // For Table Sorting: Get the appropriate sort icon for the sorting method
    const getSortIcon = (key) => {
      const isActive = sortConfig.key === key;
      const isAscending = isActive && sortConfig.direction === "ascending";
      const isDescending = isActive && sortConfig.direction === "descending";
  
      return (
        <svg
          className="w-3 h-3 ms-1.5 inline-block"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Z"
            className={isAscending ? "text-gray-900" : "text-gray-400"}
          />
          <path
            d="M15.426 12.976H8.574a2.074 2.074 0 0 0-1.847 1.086 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.086Z"
            className={isDescending ? "text-gray-900" : "text-gray-400"}
          />
        </svg>
      );
    };
  
    // For Table Sorting: Organize the rows
    const getSortedItems = (items) => {
      if (!sortConfig.key) return items;
  
      return [...items].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    };
  
    // For Table: Get filtered and sorted sellers
    const filteredAndSortedSellers = getSortedItems(
      sellers.filter((seller) =>
        Object.values(seller).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    );
  
    // For Table: Format date to Last Modified Column
    const formatDate = (date) => {
      if (!date) return "N/A";
      return new Date(date).toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
    };

    // For Table: Function to determine text color based on seller type
    const getSellerTypeColor = (type) => {
      switch (type) {
        case "BOTH":
          return "bg-purple-500";
        case "PUBLISHER":
          return "bg-addedPink";
        case "INTERMEDIARY":
          return "bg-addedLightblue";
        default:
          return "text-gray-900 bg-gray-300";
      }
    };
  // ==========================================================================
  // ==========================================================================

  // validate ads.txt
  const [validateStatus, setValidateStatus] = useState(
    "🔎 Click to validate Ads.txt"
  );
  const startValidationCheck = () => {
    const inputDomain = newSeller.seller_domain;
    const generatedAdsTxtUrl = inputDomain + "/ads.txt";
    const searchedQuery = `Added.tv, ${newSeller.seller_id}, DIRECT`;
    console.log(`🔁 Checking DIRECT line in ${generatedAdsTxtUrl}`);
    setValidateStatus(`🔁 Checking DIRECT line in ${generatedAdsTxtUrl}`);
    checkValidationUrl(generatedAdsTxtUrl, searchedQuery);
  };
  const checkValidationUrl = async (inputDomain, searchedQuery) => {
    const url = `https://zeosuhyih7xpvwvwbd3qa3ixrq0qrmly.lambda-url.eu-central-1.on.aws/fetch-ads-txt?domain=${inputDomain}`;
    console.log(`🔁 fetching url ${url}`);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      if (data.content) {
        const contentLines = data.content.split("\n");
        const normalizedSearchQuery = searchedQuery
          .toLowerCase()
          .replace(/\s+/g, "");

        const foundMatch = contentLines.some((line) => {
          const normalizedLine = line.toLowerCase().replace(/\s+/g, "");
          return normalizedLine.includes(normalizedSearchQuery);
        });

        if (foundMatch) {
          setValidateStatus("✅ Valid");
          console.log("Match found in ads.txt");
        } else {
          setValidateStatus("❌ Invalid - No matching line found");
          console.log("No match found in ads.txt");
        }
      } else {
        setValidateStatus("❌ Invalid - No content in response");
      }
    } catch (error) {
      setValidateStatus(`❌ Invalid.`);
      setError(error.message);
    }
  };

  // State Manager: Errors (For Creating/Updating Seller) - Using this to manage the Errors we show in UI when form is invalid
  const [errors, setErrors] = useState({
    seller_id: "",
    seller_name: "",
    seller_domain: "",
    seller_type: "",
  });

  // ==========================================================================
  // ==========================================================================
  // |                      Create/Update Seller                              |
  // ==========================================================================


  // State Manager: Show/Hide Seller Create and Update Modal - Using this to manage UI
  const [showSellerCreateAndUpdateModal, setShowSellerCreateAndUpdateModal] =
    useState(false);


  // For Seller Create/Update: Function to validate the inputs before creating/updating
  const validateInputs = () => {
    let newErrors = {};

    if (!newSeller.seller_id) {
      newErrors.seller_id = "Seller ID is required.";
    } else if (newSeller.seller_id.toLowerCase() === "seller_id") {
      newErrors.seller_id = '"seller_id" cannot be used as a Seller ID.';
    } else if (
      (!isEditing &&
        sellers.some((seller) => seller.seller_id === newSeller.seller_id)) ||
      (isEditing &&
        sellers.some(
          (seller) =>
            seller.seller_id === newSeller.seller_id &&
            seller.id !== currentSellerId
        ))
    ) {
      newErrors.seller_id = "Seller ID must be unique.";
    }

    if (!newSeller.seller_name) {
      newErrors.seller_name = "Seller Name is required.";
    }

    if (!newSeller.seller_domain) {
      newErrors.seller_domain = "Seller Domain is required.";
    } else {
      const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!domainPattern.test(newSeller.seller_domain)) {
        newErrors.seller_domain = "Please enter a valid domain.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // For Seller Create/Update: Function to handle add/update a seller
  const handleAddSellerClick = (e) => {
    HandleAddSeller(e, {
      validateInputs,
      isEditing,
      sellers,
      currentSellerId,
      newSeller,
      fetchSellersData,
      setIsLoadingAddingUpdatingSeller,
      setShowSellerCreateAndUpdateModal,
      resetForm,
      saveBackupSellers,
      addSeller,
      updateSeller,
      toast,
      token,
    });
  };

  // For Seller Create/Update: Function to reset the form after adding/updating a seller
  const resetForm = () => {
    setIsEditing(false);
    setNewSeller({
      seller_id: "",
      seller_name: "",
      seller_domain: "",
      seller_type: "PUBLISHER",
    });
    setCurrentSellerId(null);
  };
  // ==========================================================================
  // ==========================================================================

  // Show Bulk Upload Modal, State - using this to decide if to show this modal
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [isLoadingBulkUpload, setIsLoadingBulkUpload] = useState(false);

  // Show Delete Confromation Modal State - using this to decide if to show this modal
  const [showDeleteConformationModal, setShowDeleteConformationModal] =
    useState(false);

  // For seller delet
  const [isLoadingDeletingSeller, setIsLoadingDeletingSeller] = useState(false);

  // Bulk Upload File State - using this for bulk upload method
  const [bulkUploadFile, setBulkUploadFile] = useState(null);

  // Bulk Upload Url State - using this for bulk upload method
  const [bulkUploadUrl, setBulkUploadUrl] = useState("");

  // Bulk Upload Method State - using this for bulk upload method
  const [uploadMethod, setUploadMethod] = useState("file");

  const [isLoadingAddingUpdatingSeller, setIsLoadingAddingUpdatingSeller] =
    useState(false);

  const resetValidateAdsTxtStatus = () => {
    setValidateStatus("🔎 Click to validate Ads.txt");
  };


  // Background Styles we will use for the modal
  const bgStyle = {
    backgroundImage: `url(${"https://added.tv/wp-content/uploads/2024/10/bg-scaled.webp"})`,
    backgroundSize: "cover",
    backgroundRepeat: "repeat-y",
    zIndex: -1,
  };

  const fetchSellersData = async () => {
    if (currentUser) {
      setShowLoading(false);      
      const sellersList = await fetchSellers();
      setSellers(sellersList);
      setIsLoadingTable(false);
    }
  };


  // For Search: Handle change in search input to update search term state
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // For Sidebar: Toggle sidebar open/closed
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  // For Seller Update: Function to handle the steps after clicking edit on a seller in row
  const handleEdit = (seller) => {
    setNewSeller(seller);
    setIsEditing(true);
    setShowSellerCreateAndUpdateModal(true);
    resetValidateAdsTxtStatus();
    setCurrentSellerName(seller.seller_name);
    setCurrentSellerId(seller.id);
  };

  // For New Seller Modal: Function to handle the steps after clicking New Seller
  const handleNewSellerClick = () => {
    setShowSellerCreateAndUpdateModal(true);
    resetValidateAdsTxtStatus();
    setNewSeller({
      seller_id: "",
      seller_name: "",
      seller_domain: "",
      seller_type: "PUBLISHER",
    });
    setErrors({
      seller_id: "",
      seller_name: "",
      seller_domain: "",
      seller_type: "",
    });
  };

  // For Seller Delete: Function to handle the steps after clicking delete on a seller in row
  const handleDeleteButtonTableClick = (id, name) => {
    setShowDeleteConformationModal(true);
    setCurrentSellerId(id);
    setCurrentSellerName(name);
  };

  // [DONE] For Seller Delete Modal: Function to delete the seller after accepting delete
  const handleDeleteConforimedOnModal = async () => {
    try {
      setIsLoadingDeletingSeller(true);
      await deleteSeller(currentSellerId);
      console.log(
        `✅ Delete Seller (1/3) - Successfully deleted ${currentSellerName}`
      );
      setSellers(sellers.filter((seller) => seller.id !== currentSellerId));
      // save backup
      const formattedSellers = sellers
        .filter((seller) => seller.id !== currentSellerId) // Keep sellers that don't match `currentSellerId`
        .map(({ seller_id, seller_domain, seller_name, seller_type }) => ({
          seller_id: seller_id,
          name: seller_name,
          domain: seller_domain,
          seller_type: seller_type,
        }));

      const formattedSellersWithoutId = formattedSellers.map(
        ({ seller_id, domain, name, seller_type }) => ({
          seller_id: seller_id,
          name: name,
          domain: domain,
          seller_type: seller_type,
        })
      );
      await saveBackupSellers(formattedSellers);
      console.log(
        `✅ Delete Seller (2/3) - Successfully backuped sellers`,
        formattedSellers
      );

      // update file
      const response = await fetch("https://fdn6tia2pcldgu244yhmsk43ki0rwikv.lambda-url.eu-central-1.on.aws/createjsonfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
        },
        body: JSON.stringify(formattedSellersWithoutId),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Network response was not ok. Status: ${response.status}. Response: ${errorText}`
        );
      } else {
        console.log(
          `✅ Add Seller (3/3) - Successfully updated file`,
          "https://docs.added.tv/sellers.jsonn"
        );
      }
      setIsLoadingDeletingSeller(false);
      setShowDeleteConformationModal(false);
      // setHasChanges(true);
      toast.success(`Successfully Deleted ${newSeller.seller_name}!`);
    } catch (error) {
      setIsLoadingDeletingSeller(false);
      setShowDeleteConformationModal(false);
      toast.error(`Error Deleting ${newSeller.seller_name}!`);
      console.error("Error deleting document: ", error);
    }
  };

  const fetchBackupsData = useCallback(async () => {
    setIsLoadingRestoreBackup(true);
    setError(null);
    try {
      const fetchedBackupData = await fetchBackups();
      setBackupsData(fetchedBackupData);
      if (fetchedBackupData.length > 0) {
        setSelectedBackup(fetchedBackupData[fetchedBackupData.length - 1].id);
      }
    } catch (error) {
      setError("Failed to fetch backups. Please try again.");
    } finally {
      setIsLoadingRestoreBackup(false);
    }
  }, []);

  const formatBackupDate = (backupId) => {
    if (!backupId || typeof backupId !== "string") {
      return "";
    }

    const parts = backupId.split("_");
    if (parts.length !== 2) {
      return "Invalid Date Format";
    }

    const [datePart, timePart] = parts;
    if (datePart.length !== 8 || timePart.length !== 4) {
      return "Invalid Date Format";
    }

    const year = datePart.slice(0, 4);
    const month = datePart.slice(4, 6);
    const day = datePart.slice(6, 8);
    const hour = timePart.slice(0, 2);
    const minute = timePart.slice(2, 4);

    const date = new Date(year, month - 1, day, hour, minute);

    if (isNaN(date.getTime())) {
      return "";
    }

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    return date
      .toLocaleDateString("en-US", options)
      .replace(",", "th,")
      .replace(/(\d+):/, (match, p1) => `${p1.padStart(2, "0")}:`);
  };

  // Restore from backup step 1 - show restore backup modal after click
  const handleRestoreFromBackupClick = () => {
    setShowRestoreModal(true);
  };

  // Restore from backup step 2 - fetch backup data when modal is visible
  useEffect(() => {
    if (showRestoreModal) {
      fetchBackupsData();
    }
  }, [showRestoreModal, fetchBackupsData]);

  // Restore from backup Step 3
  const handleRestoreConfirm = async () => {
    if (!selectedBackup) {
      setError("Please select a backup to restore.");
      return;
    }

    try {
      setIsLoadingRestoreBackup(true);
      const backupData = JSON.stringify(selectedBackup); // Converts object to string
      // console.log(backupData); // This will log the string version

      // Parse the string back into a JavaScript object
      const parsedBackupData = JSON.parse(backupData);

      // Access the sellers array
      const sellersToRestore = parsedBackupData.sellers;
      console.log(
        `✅ Restore from Backup (1/5) - Sellers to restore (${sellersToRestore.length}):`,
        sellersToRestore
      );

      if (!Array.isArray(sellersToRestore) || sellersToRestore.length === 0) {
        throw new Error("Invalid backup data: No sellers found");
      }

      const newSellerIds = new Set();
      const validSellerTypes = ["PUBLISHER", "INTERMEDIARY", "BOTH"];

      const validSellers = sellersToRestore.filter((seller) => {
        if (
          !seller.seller_id ||
          !seller.name ||
          !seller.domain ||
          !seller.seller_type
        ) {
          console.error(`Missing field(s) for seller:`, seller);
          return false;
        }

        if (!validSellerTypes.includes(seller.seller_type.toUpperCase())) {
          console.error(
            `Invalid seller_type for seller:`,
            seller,
            ` got ${seller.seller_type}`
          );
          return false;
        }

        newSellerIds.add(seller.seller_id);
        return true;
      });

      if (validSellers.length === 0) {
        throw new Error("No valid sellers found in the backup data");
      }
      console.log(
        `✅ Restore from Backup (2/5) - Valid Sellers Found to restore (${validSellers.length}):`,
        validSellers
      );

      await deleteAllSellers();
      console.log(
        `✅ Restore from Backup (3/5) - Successfully deleted old Sellers list`
      );

      let restoredCount = 0;
      for (let seller of validSellers) {
        await addSeller({
          seller_id: seller.seller_id,
          seller_name: seller.name,
          seller_domain: seller.domain,
          seller_type: seller.seller_type,
        });
        restoredCount++;
      }

      // Refresh the sellers list
      // const updatedSellersList = await fetchSellers();
      setSellers(validSellers);
      console.log(
        `✅ Restore from Backup (4/5) - Successfully added Sellers (${sellers.length}) from backup.`
      );
      await fetchSellersData();
      const formattedSellers = [...sellers, newSeller].map((seller) => ({
        seller_id: seller.seller_id,
        name: seller.seller_name,
        domain: seller.seller_domain,
        seller_type: seller.seller_type,
      }));
      // Update json file
      await fetch("https://fdn6tia2pcldgu244yhmsk43ki0rwikv.lambda-url.eu-central-1.on.aws/createjsonfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
        },
        body: JSON.stringify(formattedSellers),
      });
      console.log(
        `✅ Restore from Backup (5/5) - Successfully updated file`,
        "https://docs.added.tv/sellers.json"
      );

      setIsLoadingRestoreBackup(false);
      setShowRestoreModal(false);
      toast.success(
        `Successfully restored ${restoredCount} seller(s) from backup!`
      );
    } catch (error) {
      setIsLoadingRestoreBackup(false);
      setError(`Error restoring from backup: ${error.message}`);
      console.error("Error during backup restoration:", error);
    }
  };

  // For Bulk Upload Modal: Function to toggle Modal open/closed
  const handleBulkUploadClick = () => {
    setShowBulkUploadModal(true);
  };

  // For Bulk Upload Modal: Function to set the file uploaded to the bulkUploadFile
  const handleFileChange = (event) => {
    setBulkUploadFile(event.target.files[0]);
  };

  // For Bulk Upload Modal: Function to handle bulk upload url change
  const handleUrlChange = (event) => {
    setBulkUploadUrl(event.target.value);
  };

  // For Bulk Upload Modal: Function to handle bulk upload method change
  const handleUploadMethodChange = (method) => {
    setUploadMethod(method);
  };

  // Then when calling the function:
  const handleBulkUploadClicked = (e) => {
    handleBulkUpload({
      uploadMethod,
      bulkUploadFile,
      bulkUploadUrl,
      sellers,
      setIsLoadingBulkUpload,
      setShowBulkUploadModal,
      setSellers,
      toast,
      fetchSellers,
      addSeller,
    });
  };

  // For Bulk Upload Modal: Function to handle download sample click
  const handleDownloadSample = () => {
    window.open(
      "https://addedtv.s3.us-west-2.amazonaws.com/SampleSellersBulkUpload.csv",
      "_blank"
    );
  };

  const handleCopyBackupClicked = () => {
    navigator.clipboard.writeText(JSON.stringify(selectedBackup.sellers));
    toast.success("Backup copied to clipboard!");
  };

  return (
    <div>
      {/* Navbar Component */}
      <Navbar toggleSidebar={toggleSidebar} />

      {/* Main Container for form and table */}
      <div className="flex flex-row">
        {/* Sidebar Component */}
        <Sidebar isOpen={isSidebarOpen} />

        {/* Loading Animation handler until user is identified */}
        {showLoading ? (
          // Loading Animation
          <div className="flex justify-center w-full pt-20">
            <Lottie animationData={loading} className="h-40" />
          </div>
        ) : (
          // User is identified as
          <div className="px-10 md:px-0 md:ml-[14vw] lg:ml-[8vw] mt-8 md:w-3/5 overflow-x-auto max-w-full">
            <div className="">
              <div className="container mx-auto max-w-full">
                {/* Title */}
                <h1 className="text-3xl font-semibold  mt-4 text-addedLightblue">
                  Sellers.json Manager
                </h1>
              </div>
              {/* Search box and button below title */}
              <div className="flex justify-between mt-4">
                <form className="w-[16.5rem]">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-500 "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                      placeholder="Search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      required
                    />
                  </div>
                </form>
                <div className="">
                  {/* {hasChanges && (
                    <button
                      type="submit"
                      onClick={handleUpdateFileClick}
                      className={`font-bold py-2 px-4 text-sm rounded transition duration-300 ease-in-out bg-amber-500 hover:bg-amber-600 text-white`}
                    >
                      Update File
                    </button>
                  )} */}
                  <button
                    type="submit"
                    onClick={handleNewSellerClick}
                    className={`font-bold ml-1 py-2 px-4 text-sm rounded transition duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white `}
                  >
                    New Seller
                  </button>
                  <button
                    type="button"
                    onClick={handleBulkUploadClick}
                    className={`py-1 px-2 rounded transition duration-300 ease-in-out text-xs text-addedLightblue hover:text-blue-800 hover:underline`}
                  >
                    Bulk
                  </button>
                  <button
                    type="button"
                    onClick={handleRestoreFromBackupClick}
                    className={`py-1 px-2 rounded transition duration-300 ease-in-out text-xs text-addedLightblue hover:text-blue-800 hover:underline`}
                  >
                    Restore from backup
                  </button>
                </div>
              </div>
            </div>
            {/* Sellers Table handler */}
            {isLoadingTable ? (
              // Show loading becuase stil loading table
              <div className="flex justify-center w-full pt-20">
                <Lottie animationData={loading} className="h-40" />
              </div>
            ) : (
              // Sellers Table
              <div className=" mt-4">
                <div className="max-w-full overflow-x-auto">
                  <table className="w-full max-w-full  overflow-x-auto text-xs text-left rtl:text-right text-gray-900 table-fixed">
                    <thead className="text-gray-700 uppercase bg-gray-200">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 cursor-pointer"
                          onClick={() => requestSort("seller_id")}
                        >
                          ID {getSortIcon("seller_id")}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 cursor-pointer"
                          onClick={() => requestSort("seller_name")}
                        >
                          Name {getSortIcon("seller_name")}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 cursor-pointer"
                          onClick={() => requestSort("seller_domain")}
                        >
                          Domain {getSortIcon("seller_domain")}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 cursor-pointer"
                          onClick={() => requestSort("seller_type")}
                        >
                          Type {getSortIcon("seller_type")}
                        </th>
                        <th scope="col" className="px-2 py-2 w-24">
                          Actions
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 cursor-pointer w-48"
                          onClick={() => requestSort("last_modified")}
                        >
                          Modified {getSortIcon("last_modified")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredAndSortedSellers.map((seller) => (
                        <tr
                          key={seller.id}
                          scope="row"
                          className="odd:bg-white even:bg-gray-100 hover:bg-slate-50"
                        >
                          <td className="px-6 py-4 text-gray-900 sm-break-words">
                            {seller.seller_id}
                          </td>
                          <td className="px-6 py-4 sm-break-words">
                            {seller.seller_name}
                          </td>
                          <td className="px-6 py-4 sm-break-words">
                            {seller.seller_domain}
                          </td>
                          <td className="px-2 py-4 sm-break-words w-24">
                            <p
                              className={`${getSellerTypeColor(
                                seller.seller_type
                              )} font-medium inline px-2 rounded text-white`}
                            >
                              {seller.seller_type}
                            </p>
                          </td>
                          <td className="px-2 py-6 space-x-2 flex xs:flex-col sm:flex-row w-24">
                            <button
                              onClick={() => handleEdit(seller)}
                              className="font-medium text-blue-500 hover:text-blue-800"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() =>
                                handleDeleteButtonTableClick(
                                  seller.id,
                                  seller.seller_name
                                )
                              }
                              className="font-medium text-red-500 hover:text-red-800"
                            >
                              Delete
                            </button>
                          </td>
                          <td className="px-6 py-4 sm-break-words">
                            {formatDate(seller.last_modified)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="text-center mt-2 font-extralight text-sm">
                  End of {filteredAndSortedSellers.length} total Sellers
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Seller Create/Edit Modal */}
      {showSellerCreateAndUpdateModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center px-4 sm-px-0">
          <div
            className="bg-white p-5 rounded-lg shadow-xl max-w-md w-full"
            style={bgStyle}
          >
            <h2 className="text-xl mb-4 font-bold text-addedBlue">
              {isEditing ? `Edit Seller - ${currentSellerName}` : "New Seller"}
            </h2>
            {isLoadingAddingUpdatingSeller ? (
              <Lottie animationData={loading} className="h-40" />
            ) : (
              <>
                <div className="mb-4 pb-3">
                  <div className="flex flex-col text-left w-full space-y-2">
                    <div id="seller_id">
                      <label className="w-1/4 text-left mr-2 font-semibold text-sm whitespace-nowrap">
                        Seller ID:
                      </label>
                      <div className="w-3/4">
                        <input
                          type="text"
                          value={newSeller.seller_id}
                          onChange={(e) => {
                            setNewSeller({
                              ...newSeller,
                              seller_id: e.target.value,
                            });
                            setErrors({ ...errors, seller_id: "" });
                          }}
                          placeholder="Type in Seller ID"
                          className={`w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border-gray-300`}
                        />
                        {errors.seller_id && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.seller_id}
                          </p>
                        )}
                      </div>
                    </div>
                    <div id="seller_name">
                      <label className="w-1/4 text-left mr-2 font-semibold text-sm whitespace-nowrap">
                        Seller Name:
                      </label>
                      <div className="w-3/4">
                        <input
                          type="text"
                          value={newSeller.seller_name}
                          onChange={(e) => {
                            setNewSeller({
                              ...newSeller,
                              seller_name: e.target.value,
                            });
                            setErrors({ ...errors, seller_name: "" });
                          }}
                          placeholder="Type in Seller Name"
                          className={`w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border-gray-300`}
                        />
                        {errors.seller_name && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.seller_name}
                          </p>
                        )}
                      </div>
                    </div>
                    <div id="seller_domain">
                      <label className="w-1/4 text-left mr-2 font-semibold text-sm whitespace-nowrap">
                        Seller Domain:
                      </label>
                      <div className="w-3/4">
                        <input
                          type="text"
                          value={newSeller.seller_domain}
                          onChange={(e) => {
                            setNewSeller({
                              ...newSeller,
                              seller_domain: e.target.value,
                            });
                            setErrors({ ...errors, seller_domain: "" });
                          }}
                          placeholder="Type in Seller Domain"
                          className={`w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border-gray-300`}
                        />
                        {errors.seller_domain && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.seller_domain}
                          </p>
                        )}
                      </div>
                    </div>
                    <div id="seller_type">
                      <label className="w-1/4 text-left mr-2 font-semibold text-sm whitespace-nowrap">
                        Seller Type:
                      </label>
                      <select
                        name=""
                        value={newSeller.seller_type}
                        onChange={(e) => {
                          setNewSeller({
                            ...newSeller,
                            seller_type: e.target.value,
                          });
                          setErrors({ ...errors, seller_type: "" });
                        }}
                        className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm border-gray-300 select-none`}
                      >
                        <option value="PUBLISHER">PUBLISHER</option>
                        <option value="INTERMEDIARY">INTERMEDIARY</option>
                        <option value="BOTH">BOTH</option>
                      </select>
                      {errors.seller_type && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.seller_type}
                        </p>
                      )}
                    </div>
                    {isValid && (
                      <div id="validate_adstxt">
                        <label
                          className={`w-1/4 text-left mr-2 text-sm whitespace-nowrap 
                          ${
                            validateStatus.codePointAt(0) === 0x1f50e
                              ? "hover:font-semibold cursor-pointer"
                              : ""
                          }
                          ${
                            validateStatus.codePointAt(0) === 0x274c
                              ? "text-red-500 select-none"
                              : ""
                          } 
                          ${
                            validateStatus.codePointAt(0) === 0x1f501
                              ? "text-addedLightblue select-none"
                              : ""
                          }
                          ${
                            validateStatus.codePointAt(0) === 0x2705
                              ? "text-green-500 select-none"
                              : ""
                          }
                        `}
                          onClick={
                            validateStatus.codePointAt(0) === 0x1f50e
                              ? startValidationCheck
                              : null
                          }
                        >
                          {validateStatus}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={() => setShowSellerCreateAndUpdateModal(false)}
                    className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleAddSellerClick}
                    className={`px-4 py-2 
                   bg-blue-500 hover:bg-blue-600"
                   
               text-white rounded`}
                  >
                    {isEditing ? "Update" : "Create"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {showBulkUploadModal && (
        <BulkUploadModal
          isLoadingBulkUpload={isLoadingBulkUpload}
          uploadMethod={uploadMethod}
          loading={loading}
          bulkUploadFile={bulkUploadFile}
          bulkUploadUrl={bulkUploadUrl}
          setShowBulkUploadModal={setShowBulkUploadModal}
          handleDownloadSample={handleDownloadSample}
          handleUploadMethodChange={handleUploadMethodChange}
          handleFileChange={handleFileChange}
          handleUrlChange={handleUrlChange}
          handleBulkUploadClicked={handleBulkUploadClicked}
          bgStyle={bgStyle}
        />
      )}

      <RestoreBackupModal
        showRestoreModal={showRestoreModal}
        isLoadingRestoreBackup={isLoadingRestoreBackup}
        error={error}
        backupsData={backupsData}
        loading={loading}
        selectedBackup={selectedBackup}
        setSelectedBackup={setSelectedBackup}
        handleCopyBackupClicked={handleCopyBackupClicked}
        handleRestoreConfirm={handleRestoreConfirm}
        setShowRestoreModal={setShowRestoreModal}
        formatBackupDate={formatBackupDate}
      />

      <DeleteConfirmationModal
        showDeleteConformationModal={showDeleteConformationModal}
        bgStyle={bgStyle}
        isLoadingDeletingSeller={isLoadingDeletingSeller}
        loading={loading}
        currentSellerName={currentSellerName}
        handleDeleteConforimedOnModal={handleDeleteConforimedOnModal}
        setShowDeleteConformationModal={setShowDeleteConformationModal}
      />

      {/* Alerts */}
      <>
        <Toaster position="top-right" />
      </>
    </div>
  );
};

export default SellersJsonManager;
