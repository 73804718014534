import ReactDOM from "react-dom/client";
import RoutesManager from "./playground/RoutesManager";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./playground/auth/AuthContext";
import "firebase/auth";
import "./playground/assets/css/index.css";


// Create the root element
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the application
root.render(
  <AuthProvider>
    <BrowserRouter>
      <RoutesManager />
    </BrowserRouter>
  </AuthProvider>
);
