// Files and Module Imports
import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth/AuthContext";
import { Navbar } from "../../layout/Navbar";
import Lottie from "lottie-react";
import loading from "../../assets/css/loading.json";
import { Sidebar } from "../../layout/Sidebar";
import toast, { Toaster } from "react-hot-toast";
import { DiscrepancyTable } from "./view/DiscrepancyTable";
import { DiscrepancyChart } from "./view/DiscrepancyChart";
import { fetchAdvertisers } from "./methods/CrudFunctions";
import AdvertisersManager from "./manage/AdvertisersManager";
import { FetchDataFromAdded, FetchDataFromDemand } from "./methods/FetchData";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const DiscrepancyCheck = (props) => {
  // All state declarations at the top
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [dateRange, setDateRange] = useState("yesterday");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [advertisers, setAdvertisers] = useState([]);
  const [activeView, setActiveView] = useState("view");
  const [searchTerm, setSearchTerm] = useState("");
  const [fetchedData, setFetchedData] = useState({
    tableData: [],
    chartData: [],
  });

  // Computed loading state
  const isDataLoading = tableLoading || chartLoading;

  // const isDataLoading = false;
  // Add a new state for temporary selections
  const [tempSelectedAdvertisers, setTempSelectedAdvertisers] = useState([]);

  // Initialize both selected and temp selected advertisers when advertisers are loaded
  useEffect(() => {
    if (advertisers.length > 0) {
      setSelectedAdvertisers(advertisers.map((a) => a.id));
      setTempSelectedAdvertisers(advertisers.map((a) => a.id));
      setIsAllSelected(true);
    }
  }, [advertisers]);

  // Current user variable - using this for validating the current user
  const { currentUser } = useAuth();

  // Hide loading if current user is identified
  useEffect(() => {
    setShowLoading(false);
    fetchAdvertisers().then(setAdvertisers);
  }, [currentUser]);

  // Background Styles
  const bgStyle = {
    backgroundImage: `url(${"https://added.tv/wp-content/uploads/2024/10/bg-scaled.webp"})`,
    backgroundSize: "cover",
    backgroundRepeat: "repeat-y",
    backgroundAttachment: "fixed",
    minHeight: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  };

  // Function to handle search change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Request to Added Sample
  // http://localhost:3060/get-added-data?startDate=2024-11-05&endDate=2024-11-08&marketplace=XXXX&advertiser=XXXX

  // For Sidebar: Toggle sidebar open/closed
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Add formatDate utility function
  const formatDate = (date) => {
    return date.toISOString().split("T")[0]; // Returns YYYY-MM-DD format
  };

  const isValidNumber = (value) => typeof value === "number" && !isNaN(value);

  // Update dates when dateRange changes
  useEffect(() => {
    const today = new Date();

    switch (dateRange) {
      case "yesterday":
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        setStartDate(formatDate(yesterday));
        setEndDate(formatDate(yesterday));
        break;

      case "last3":
        const threeDaysAgo = new Date(today);
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        const yesterdayForLast3 = new Date(today);
        yesterdayForLast3.setDate(yesterdayForLast3.getDate() - 1);
        setStartDate(formatDate(threeDaysAgo));
        setEndDate(formatDate(yesterdayForLast3));
        break;

      case "last7":
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        const yesterdayForLast7 = new Date(today);
        yesterdayForLast7.setDate(yesterdayForLast7.getDate() - 1);
        setStartDate(formatDate(sevenDaysAgo));
        setEndDate(formatDate(yesterdayForLast7));
        break;

      case "mtd":
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const yesterdayForMTD = new Date(today);
        yesterdayForMTD.setDate(yesterdayForMTD.getDate() - 1);
        setStartDate(formatDate(firstDayOfMonth));
        setEndDate(formatDate(yesterdayForMTD));
        break;

      case "custom":
        setShowDatePicker(false);
        break;

      default:
        break;
    }
  }, [dateRange]);

  // Add new state for picker visibility
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  // Initialize states for date handling
  const [selectedRange, setSelectedRange] = useState({
    from: undefined,
    to: undefined,
  });

  const [tempDateRange, setTempDateRange] = useState({
    from: undefined,
    to: undefined,
  });

  // Initialize with yesterday's date
  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    setStartDate(formatDate(yesterday));
    setEndDate(formatDate(yesterday));
    setSelectedRange({ from: yesterday, to: yesterday });
    setTempDateRange({ from: yesterday, to: yesterday });
  }, []);

  // Separate handlers for each component
  const handleTableLoadingComplete = () => {
    setTableLoading(false);
  };

  const handleChartLoadingComplete = () => {
    setChartLoading(false);
  };

  // Reset loading states when date or advertisers change
  // useEffect(() => {
  //   // setTableLoading(true);
  //   // setChartLoading(true);
  // }, [startDate, endDate, advertisers]);

  useEffect(() => {
    console.log("🔄 Fetching data, fetchTrigger:", fetchTrigger);
    if (fetchTrigger === 0) {
      return;
    }

    const fetchData = async () => {
      if (!startDate || !endDate || !advertisers.length) {
        handleTableLoadingComplete();
        handleChartLoadingComplete();
        return;
      }

      // Add server health check
      try {
        const serverCheck = await fetch('http://localhost:3060/server-test');
        const serverStatus = await serverCheck.json();
        
        if (!serverCheck.ok || serverStatus.message !== "Server is running!") {
          toast.error("Server is not responding. Please try again later.");
          handleTableLoadingComplete();
          handleChartLoadingComplete();
          return;
        }
      } catch (error) {
        console.error("Server health check failed:", error);
        toast.error("Cannot connect to server. Please check if the server is running.");
        handleTableLoadingComplete();
        handleChartLoadingComplete();
        return;
      }

      // Set loading states before fetch
      setTableLoading(true);
      setChartLoading(true);

      try {
        const isSingleDate = startDate === endDate;

        // Clear previous data before fetching new data
        setFetchedData({
          tableData: [],
          chartData: [],
        });

        // Fetch data for all advertisers in parallel
        const processedResults = await Promise.all(
          (selectedAdvertisers.length > 0
            ? advertisers.filter((adv) => selectedAdvertisers.includes(adv.id))
            : advertisers
          ).map(async (advertiser) => {
            if (!advertiser.ids?.[0]) return null;

            try {
              // Fetch all responses for the advertiser
              const responses = await Promise.all([
                FetchDataFromDemand(
                  advertiser,
                  startDate,
                  endDate,
                  isSingleDate
                ),
                // Handle multiple Added IDs if they exist
                ...(advertiser.ids || []).map(id => 
                  FetchDataFromAdded(
                    startDate,
                    endDate,
                    advertiser.idType === "advertiser" ? id : null,
                    advertiser.idType === "marketplace" ? id : null,
                    isSingleDate
                  )
                ),
                // Handle additional marketplace IDs if they exist
                ...(advertiser.marketplaceIds || []).map(id => 
                  FetchDataFromAdded(
                    startDate,
                    endDate,
                    null,
                    id,
                    isSingleDate
                  )
                ),
                // Handle additional advertiser IDs if they exist
                ...(advertiser.advertiserIds || []).map(id => 
                  FetchDataFromAdded(
                    startDate,
                    endDate,
                    id,
                    null,
                    isSingleDate
                  )
                )
              ]);

              // Process all responses
              const processedData = responses.map((response, index) => {
                const dataSource = index === 0 ? "demand" : "added";
                const totals = response.data.find((item) =>
                  item[isSingleDate ? "hour" : "date"].startsWith("Total")
                );

                return {
                  [dataSource]: {
                    totals,
                    chartData: response.data
                      .filter(
                        (item) =>
                          !item[isSingleDate ? "hour" : "date"].startsWith(
                            "Total"
                          )
                      )
                      .sort((a, b) => {
                        if (isSingleDate) {
                          return (
                            parseInt(a.hour.split("-").pop()) -
                            parseInt(b.hour.split("-").pop())
                          );
                        }
                        const dateA = new Date(a.date);
                        const dateB = new Date(b.date);
                        return dateA.getTime() - dateB.getTime();
                      }),
                  },
                };
              });

              // Combine processed data
              const demandData = processedData[0]?.demand;
              // Combine data from all Added responses (index 1 and onwards)
              const addedData = processedData.slice(1).reduce((acc, curr) => {
                if (!curr.added) return acc;
                if (!acc) return curr.added;

                return {
                  totals: {
                    impressions: (acc.totals?.impressions || 0) + (curr.added.totals?.impressions || 0),
                    revenue: (acc.totals?.revenue || 0) + (curr.added.totals?.revenue || 0),
                  },
                  chartData: acc.chartData.map((item, index) => ({
                    ...item,
                    impressions: (item.impressions || 0) + (curr.added.chartData[index]?.impressions || 0),
                    revenue: (item.revenue || 0) + (curr.added.chartData[index]?.revenue || 0),
                  })),
                };
              }, null);

              // Calculate discrepancies
              const addedImps = addedData?.totals?.impressions || 0;
              const demandImps = demandData?.totals?.impressions || 0;
              const addedRev = Number(addedData?.totals?.revenue || 0);
              const demandRev = Number(demandData?.totals?.revenue || 0);
              const impsDiscrepancy =
                isValidNumber(addedImps) && isValidNumber(demandImps)
                  ? demandImps === 0
                    ? addedImps === 0
                      ? "0.00"
                      : "100.00"
                    : (((demandImps - addedImps) / demandImps) * 100).toFixed(2)
                  : "ERR";

              const revDiscrepancy =
                isValidNumber(addedRev) && isValidNumber(demandRev)
                  ? demandRev === 0
                    ? addedRev === 0
                      ? "0.00"
                      : "100.00"
                    : (((demandRev - addedRev) / demandRev) * 100).toFixed(2)
                  : "ERR";

              const revDiscrepancyDollar =
                isValidNumber(addedRev) && isValidNumber(demandRev)
                  ? (demandRev - addedRev).toFixed(2)
                  : "ERR";

              return {
                name: advertiser.name,
                id: advertiser.id,
                tableData: {
                  name: advertiser.name,
                  demand_imps: demandData?.totals?.impressions,
                  added_imps: addedData?.totals?.impressions,
                  demand_revenue: Number(demandData?.totals?.revenue).toFixed(
                    2
                  ),
                  added_revenue: Number(addedData?.totals?.revenue).toFixed(2),
                  imps_discrepancy: impsDiscrepancy,
                  rev_discrepancy: revDiscrepancy,
                  rev_discrepancy_dollar: revDiscrepancyDollar,
                },
                chartData: {
                  added: addedData?.chartData || [],
                  demand: demandData?.chartData || [],
                },
              };
            } catch (error) {
              console.error(
                `Error processing data for advertiser ${advertiser.name}:`,
                error
              );
              return null;
            }
          })
        );

        // Filter out null results and set fetched data
        const validResults = processedResults.filter(Boolean);

        setFetchedData({
          tableData: validResults.map((r) => r.tableData),
          chartData: validResults.map((r) => ({
            id: r.id,
            name: r.name,
            chartData: {
              added: r.chartData.added,
              demand: r.chartData.demand,
            },
          })),
        });

        handleTableLoadingComplete();
        handleChartLoadingComplete();
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      } finally {
        handleTableLoadingComplete();
        handleChartLoadingComplete();
      }
    };

    fetchData();
  }, [fetchTrigger]);

  return (
    <div>
      {/* Navbar Component */}
      <Navbar toggleSidebar={toggleSidebar} />

      {/* Main Container for form and table */}
      <div className="flex flex-row">
        {/* Sidebar Component */}
        <Sidebar isOpen={isSidebarOpen} />

        {/* Loading Animation handler until user is identified */}
        {showLoading ? (
          // Loading Animation
          <div className="flex justify-center w-full pt-20">
            <Lottie animationData={loading} className="h-40" />
          </div>
        ) : (
          // User is identified
          <div className="px-10 md:px-0 md:ml-[14vw] lg:ml-[8vw] mt-8 md:w-3/5 overflow-x-auto max-w-full">
            <div className="">
              <div className="container mx-auto max-w-full">
                {/* Title */}
                <h1 className="text-3xl font-semibold  mt-4 text-addedLightblue">
                  Discrepancy Check
                </h1>
              </div>
              {/* Selection between View and Manage, if one active we apply button border-b */}
              <div className="flex gap-2 mt-4">
                {/* Button View */}
                <button
                  className={`px-4 py-2 ${
                    activeView === "view"
                      ? "border-b-2 border-addedLightblue"
                      : "hover:bg-gray-50 hover:text-gray-800 text-gray-500"
                  }`}
                  onClick={() => {
                    if (!activeView === "view") {
                      setFetchTrigger((prev) => prev + 1);
                    }
                    setActiveView("view");
                  }}
                >
                  View
                </button>
                {/* Button Manage */}
                <button
                  className={`px-4 py-2 ${
                    activeView === "manage"
                      ? "border-b-2 border-addedLightblue"
                      : "hover:bg-gray-50 hover:text-gray-800 text-gray-500"
                  }`}
                  onClick={() => setActiveView("manage")}
                >
                  Manage
                </button>
              </div>
              {/* Search box and button below title */}
              {activeView === "manage" && (
                <AdvertisersManager advertisers={advertisers} />
              )}
              {activeView === "view" && (
                <div className="flex flex-col mt-4">
                  <div>
                    <div className="mt-4 flex justify-between items-center">
                      <h2 className="text-xl font-semibold">Table</h2>
                      <div className="flex flex-row gap-2 items-center justify-end">
                        <p>Date:</p>
                        <button
                          onClick={() =>
                            !isDataLoading &&
                            setIsDatePickerOpen(!isDatePickerOpen)
                          }
                          className={`border-2 border-gray-300 rounded-md px-2 py-1 text-sm min-w-[200px] ${
                            isDataLoading
                              ? "bg-gray-50 cursor-not-allowed opacity-50"
                              : "hover:bg-gray-100"
                          }`}
                          disabled={isDataLoading}
                        >
                          {`${format(
                            new Date(startDate),
                            "yyyy-MM-dd"
                          )} - ${format(new Date(endDate), "yyyy-MM-dd")}`}
                        </button>

                        <button
                          onClick={() =>
                            !isDataLoading && setIsFilterOpen(!isFilterOpen)
                          }
                          className={`flex items-center gap-2 rounded-md px-3 py-1 text-sm ${
                            isDataLoading
                              ? "bg-gray-50 cursor-not-allowed opacity-50"
                              : "hover:bg-gray-100"
                          } ${
                            selectedAdvertisers.length === advertisers.length
                              ? "border-2 border-gray-300"
                              : "text-addedPink font-semibold border-2 border-addedPink"
                          }`}
                          disabled={isDataLoading}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                            />
                          </svg>
                          <span
                            className={
                              selectedAdvertisers.length === advertisers.length
                                ? ""
                                : "text-addedPink font-semibold"
                            }
                          >
                            {selectedAdvertisers.length ===
                              advertisers.length && "All"}
                            {selectedAdvertisers.length === 1 &&
                              `Advertiser: ${
                                advertisers.find(
                                  (a) => a.id === selectedAdvertisers[0]
                                )?.name
                              }`}
                            {selectedAdvertisers.length > 1 &&
                              selectedAdvertisers.length < advertisers.length &&
                              `${selectedAdvertisers.length} selected`}
                          </span>
                        </button>
                        <button
                          className={`flex items-center gap-2 rounded-md px-3 py-1 text-sm border-2 ${
                            isDataLoading
                              ? "bg-gray-50 cursor-not-allowed opacity-50 border-gray-300"
                              : "hover:bg-addedLightblue/80 bg-addedLightblue text-white border-addedLightblue"
                          }`}
                          onClick={() => {
                            setFetchTrigger((prev) => prev + 1);
                          }}
                        >
                          Run
                        </button>
                      </div>
                    </div>

                    {/* Modified Filter Dropdown */}
                    {isFilterOpen && !isDataLoading && (
                      <div
                        className="fixed inset-0 bg-black bg-opacity-30"
                        onClick={() => setIsFilterOpen(false)}
                        style={{ zIndex: 1000 }}
                      >
                        <div
                          className="absolute shadow-xl rounded-lg border"
                          style={{
                            top: "180px",
                            right: "220px",
                            backgroundImage: bgStyle.backgroundImage,
                            backgroundSize: bgStyle.backgroundSize,
                            backgroundRepeat: bgStyle.backgroundRepeat,
                            width: "300px",
                            maxHeight: "500px",
                            zIndex: 1001,
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="flex flex-col h-full">
                            <div className="p-4">
                              <div className="flex justify-between items-center mb-4 border-b pb-2">
                                <h3 className="font-semibold text-lg">
                                  Filter Advertisers
                                </h3>
                                <button
                                  onClick={() => {
                                    setTempSelectedAdvertisers([]);
                                    setIsAllSelected(false);
                                  }}
                                  className="text-sm text-gray-500 hover:text-gray-700"
                                >
                                  Clear All
                                </button>
                              </div>

                              <div className="mb-2 pb-2 border-b">
                                <div className="flex items-center mb-2">
                                  <input
                                    type="checkbox"
                                    id="select-all"
                                    checked={isAllSelected}
                                    onChange={(e) => {
                                      setIsAllSelected(e.target.checked);
                                      if (e.target.checked) {
                                        setTempSelectedAdvertisers(
                                          advertisers.map((a) => a.id)
                                        );
                                      } else {
                                        setTempSelectedAdvertisers([]);
                                      }
                                    }}
                                    className="mr-2 h-4 w-4 rounded border-gray-300 text-addedLightblue cursor-pointer focus:ring-0"
                                  />
                                  <label
                                    htmlFor="select-all"
                                    className="font-medium cursor-pointer"
                                  >
                                    Select All
                                  </label>
                                </div>
                              </div>

                              <div className="max-h-[250px] overflow-y-auto">
                                {advertisers
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((advertiser) => (
                                  <div
                                    key={advertiser.id}
                                    className="flex items-center py-2 hover:bg-gray-50 px-1 rounded"
                                  >
                                    <input
                                      type="checkbox"
                                      id={`advertiser-${advertiser.id}`}
                                      checked={tempSelectedAdvertisers.includes(
                                        advertiser.id
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          const newSelected = [
                                            ...tempSelectedAdvertisers,
                                            advertiser.id,
                                          ];
                                          setTempSelectedAdvertisers(
                                            newSelected
                                          );
                                          setIsAllSelected(
                                            newSelected.length ===
                                              advertisers.length
                                          );
                                        } else {
                                          const newSelected =
                                            tempSelectedAdvertisers.filter(
                                              (id) => id !== advertiser.id
                                            );
                                          setTempSelectedAdvertisers(
                                            newSelected
                                          );
                                          setIsAllSelected(false);
                                        }
                                      }}
                                      className="mr-2 h-4 w-4 rounded border-gray-300 text-addedLightblue cursor-pointer focus:ring-0"
                                    />
                                    <label
                                      htmlFor={`advertiser-${advertiser.id}`}
                                      className="cursor-pointer flex-1"
                                    >
                                      {advertiser.name}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>

                            {/* Footer with Apply button */}
                            <div className="mt-auto p-4 bg-gray-50 border-t flex justify-end gap-2">
                              <button
                                onClick={() => {
                                  setIsFilterOpen(false);
                                  setTempSelectedAdvertisers(
                                    selectedAdvertisers
                                  ); // Reset to current selection
                                  setIsAllSelected(
                                    selectedAdvertisers.length ===
                                      advertisers.length
                                  );
                                }}
                                className="px-4 py-2 text-gray-600 hover:text-gray-800"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={() => {
                                  setIsFilterOpen(false);
                                  setSelectedAdvertisers(
                                    tempSelectedAdvertisers
                                  );
                                }}
                                className={`px-6 py-2 bg-addedLightblue text-white rounded-md hover:bg-addedLightblue/80 font-medium
                                  ${
                                    tempSelectedAdvertisers.length === 0
                                      ? "bg-gray-300 cursor-not-allowed opacity-50"
                                      : ""
                                  }`}
                                disabled={tempSelectedAdvertisers.length === 0}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <DiscrepancyTable
                      tableData={fetchedData.tableData}
                      isLoading={isDataLoading}
                      onLoadingComplete={handleTableLoadingComplete}
                    />
                  </div>
                  <div>
                    <DiscrepancyChart
                      chartData={fetchedData.chartData}
                      startDate={startDate}
                      endDate={endDate}
                      isLoading={isDataLoading}
                      onLoadingComplete={handleChartLoadingComplete}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Alerts */}
      <>
        <Toaster position="top-right" />
      </>

      {isDatePickerOpen && !isDataLoading && (
        <div
          className="fixed inset-0"
          onClick={() => setIsDatePickerOpen(false)}
        >
          <div
            className="absolute z-50 shadow-lg rounded-lg border"
            style={{
              top: "180px",
              right: "220px",
              backgroundImage: bgStyle.backgroundImage,
              backgroundSize: bgStyle.backgroundSize,
              backgroundRepeat: bgStyle.backgroundRepeat,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <DayPicker
              mode="range"
              defaultMonth={new Date(startDate)}
              selected={tempDateRange}
              numberOfMonths={1}
              disabled={{
                after: new Date(new Date().setDate(new Date().getDate())),
              }}
              onSelect={setTempDateRange}
              showOutsideDays
              className="p-3"
            />

            <div className="flex justify-end pb-2 pr-2 -mt-2">
              {/* Cancel Button */}
              <button
                onClick={() => {
                  setIsDatePickerOpen(false);
                  setTempDateRange(selectedRange); // Revert to previously selected range
                }}
                className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 cursor-pointer"
              >
                Cancel
              </button>
              {/* Select Button */}
              <button
                className="bg-addedLightblue text-white px-4 py-2 rounded-md hover:bg-addedLightblue/80 cursor-pointer disabled:bg-gray-300 disabled:cursor-not-allowed"
                onClick={() => {
                  if (tempDateRange.from && tempDateRange.to) {
                    // Update the actual date range
                    setStartDate(format(tempDateRange.from, "yyyy-MM-dd"));
                    setEndDate(format(tempDateRange.to, "yyyy-MM-dd"));
                    setSelectedRange(tempDateRange);
                    // Close the picker
                    setIsDatePickerOpen(false);
                  }
                }}
              >
                Select
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscrepancyCheck;
