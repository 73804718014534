import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router";

// Sidebar component to manage the sidebar navigation and resizing functionality
export const Sidebar = ({ isOpen }) => {
  const location = useLocation();
  const sidebarRef = useRef(null);
  const [sidebarWidth, setSidebarWidth] = useState(256); // Default width 64px * 4 (tailwind's w-64)
  const [isResizing, setIsResizing] = useState(false);

  // Function to check if a route is active
  const isActive = (route) => {
    return location.pathname === route;
  };

  // Function to start resizing
  const startResizing = React.useCallback((mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    setIsResizing(true);
  }, []);

  // Function to stop resizing
  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  // Function to handle resizing
  const resize = React.useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        const newWidth =
          mouseMoveEvent.clientX -
          sidebarRef.current.getBoundingClientRect().left;
        if (newWidth >= 64 && newWidth <= 256) {
          setSidebarWidth(newWidth);
        }
      }
    },
    [isResizing]
  );

  // Add event listeners for mouse move and mouse up events
  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  // Determine if the sidebar is collapsed
  const isCollapsed = sidebarWidth <= 170;

  return (
    <div
      ref={sidebarRef}
      style={{ width: `${sidebarWidth}px` }}
      className={`h-dvh transition-all duration-300 border-r border-gray-200 bg-white relative
                  ${isOpen ? "" : "hidden"} md:block`}
    >
      <div className="overflow-y-auto py-5 px-3 h-full">
        <div className="flex flex-col justify-between h-screen">
          <div className="main-content">
            <div id="productDemo" className="space-y-2">
              <div className="flex flex-col space-y-2">
                {/* Discrepancy Section
                <a
                  className={`flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 group 
                              ${
                                isActive("/discrepancy")
                                  ? "bg-gray-200"
                                  : "hover:bg-gray-100"
                              }
                              ${isCollapsed ? "justify-center" : ""}`}
                  href="/discrepancy"
                >
                    <svg
                      className="w-6 h-6 text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4Zm12 12V5H7v11h10Zm-5 1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  {!isCollapsed && <span className="ml-3">Discrepancy</span>}
                </a> */}

                {/* Sellers Manager Section */}
                <a
                  className={`flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 group 
                              ${
                                isActive("/sellers")
                                  ? "bg-gray-200"
                                  : "hover:bg-gray-100"
                              }
                              ${isCollapsed ? "justify-center" : ""}`}
                  href="/sellers"
                >
                    <svg
                      className="w-6 h-6 text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  {!isCollapsed && <span className="ml-3">Sellers Manager</span>}
                </a>

                {/* Crawler Section */}
                <a
                  className={`flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 group 
                              ${
                                isActive("/crawler")
                                  ? "bg-gray-200"
                                  : "hover:bg-gray-100"
                              }
                              ${isCollapsed ? "justify-center" : ""}`}
                  href="/crawler"
                >
                  <svg
                    className="w-6 h-6 text-gray-500 transition duration-75"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M17.44 3a1 1 0 0 1 .707.293l2.56 2.56a1 1 0 0 1 0 1.414L18.194 9.78 14.22 5.806l2.513-2.513A1 1 0 0 1 17.44 3Zm-4.634 4.22-9.513 9.513a1 1 0 0 0 0 1.414l2.56 2.56a1 1 0 0 0 1.414 0l9.513-9.513-3.974-3.974ZM6 6a1 1 0 0 1 1 1v1h1a1 1 0 0 1 0 2H7v1a1 1 0 1 1-2 0v-1H4a1 1 0 0 1 0-2h1V7a1 1 0 0 1 1-1Zm9 9a1 1 0 0 1 1 1v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 1 1 0-2h1v-1a1 1 0 0 1 1-1Z"
                      clipRule="evenodd"
                    />
                    <path d="M19 13h-2v2h2v-2ZM13 3h-2v2h2V3Zm-2 2H9v2h2V5ZM9 3H7v2h2V3Zm12 8h-2v2h2v-2Zm0 4h-2v2h2v-2Z" />
                  </svg>
                  {!isCollapsed && <span className="ml-3">Crawler</span>}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute top-0 right-0 w-1 h-full cursor-col-resize hover:bg-gray-300"
        onMouseDown={startResizing}
      />
    </div>
  );
};
