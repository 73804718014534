import Lottie from "lottie-react";
import React from "react";

const DeleteConfirmationModal = ({
  showDeleteConformationModal,
  bgStyle,
  isLoadingDeletingSeller,
  loading,
  currentSellerName,
  handleDeleteConforimedOnModal,
  setShowDeleteConformationModal,
}) => {
  if (!showDeleteConformationModal) return null; // If modal is not to be shown, return null

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center px-4 sm-px-0">
      <div
        className="bg-white p-5 rounded-lg shadow-xl max-w-md w-full"
        style={bgStyle}
      >
        <h2 className="text-xl mb-4 font-bold text-addedBlue">Delete Seller</h2>
        {isLoadingDeletingSeller ? (
          <Lottie animationData={loading} className="h-40" />
        ) : (
          <>
            <div className="mb-4 pb-3">
              <div className="flex flex-col text-left w-full space-y-2">
                <div className="sm-w-1/4 text-left mr-2 font-semibold text-sm  break-words">
                  Are you sure you want to delete{" "}
                  <span className="text-red-700 font-bold">
                    {currentSellerName}
                  </span>
                  ?
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setShowDeleteConformationModal(false)}
                className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConforimedOnModal}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
