import { useState } from "react";
import { Navbar } from "../../layout/Navbar";
import { Sidebar } from "../../layout/Sidebar";

// NotFound component for displaying a 404 error page
export const NotFound = () => {
  // State to manage the sidebar's open/close status
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Function to toggle the sidebar open/close status
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      {/* Main Alignment */}
      <Navbar toggleSidebar={toggleSidebar} />
      {/* Sidebar and Content */}
      <div className="flex flex-row">
        {/* Sidebar */}
        <Sidebar isOpen={isSidebarOpen} />
        {/* Content */}
        <div className="flex flex-grow justify-center">
          <div className="p-4 mt-14 min-h-screen">
            {/* 404 Content */}
            <section className="bg-white dark:bg-gray-900">
              <div className="mt-12 py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                  <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-addedPink">
                    404
                  </h1>
                  <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                    Something's missing.
                  </p>
                  <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                    Sorry, we couldn't find the page you were looking for.
                  </p>
                  <a
                    href="/"
                    className="inline-flex text-white bg-addedPink hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4"
                  >
                    Back to Homepage
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
