import { useState } from "react";

const AdvertisersTable = ({ advertisers, searchTerm, onEdit, onDelete }) => {
  // Add sorting state
  const [sortConfig, setSortConfig] = useState({
    key: "last_modified",
    direction: "descending"
  });

  // Sorting request handler
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Sort icon component
  const getSortIcon = (key) => {
    const isActive = sortConfig.key === key;
    const isAscending = isActive && sortConfig.direction === "ascending";
    const isDescending = isActive && sortConfig.direction === "descending";

    return (
      <svg
        className="w-3 h-3 ms-1.5 inline-block"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Z"
          className={isAscending ? "text-gray-900" : "text-gray-400"}
        />
        <path
          d="M15.426 12.976H8.574a2.074 2.074 0 0 0-1.847 1.086 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.086Z"
          className={isDescending ? "text-gray-900" : "text-gray-400"}
        />
      </svg>
    );
  };

  // Sorting function
  const getSortedItems = (items) => {
    if (!sortConfig.key) return items;

    return [...items].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  };

  // Filter and sort advertisers
  const getFilteredAndSortedItems = (items) => {
    // First filter
    const filteredItems = items.filter((advertiser) =>
      Object.values(advertiser).some((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
        if (Array.isArray(value)) {
          return value.some(v => 
            typeof v === 'string' && v.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
        if (typeof value === 'object' && value !== null) {
          return Object.values(value).some(v => 
            typeof v === 'string' && v.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
        return false;
      })
    );

    // Then sort
    return getSortedItems(filteredItems);
  };

  // Get filtered and sorted advertisers
  const filteredAndSortedAdvertisers = getFilteredAndSortedItems(advertisers);

  // Function to format API URL for display
  const formatApiUrl = (apis) => {
    // If apis is an object with multiple URLs
    return Object.entries(apis).map(([key, url]) => (
      <ol key={key}>
        <li className={`${apis.length > 1 ? "list-disc" : ""}`}>
          <a
            key={key}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 underline truncate block mb-1"
          >
            {url}
          </a>
        </li>
      </ol>
    ));

    return null;
  };

  return (
    <div>
      <div className="mt-4">
        <div className="max-w-full overflow-x-auto flex justify-center mt-2 mb-12">
          <table className="w-full max-w-full overflow-x-auto text-xs text-left rtl:text-right text-gray-900 table-fixed border">
            <thead className="text-gray-700 uppercase bg-gray-200">
              <tr className="text-center">
                <th 
                  scope="col" 
                  className="px-2 py-2 w-24 cursor-pointer"
                  onClick={() => requestSort("name")}
                >
                  Name {getSortIcon("name")}
                </th>
                <th 
                  scope="col" 
                  className="px-2 py-2 w-24 cursor-pointer"
                  onClick={() => requestSort("method")}
                >
                  Method {getSortIcon("method")}
                </th>
                <th scope="col" className="px-2 py-2 w-24">
                  Advertiser IDs
                </th>
                <th scope="col" className="px-2 py-2 w-24">
                  Marketplace IDs
                </th>
                <th scope="col" className="px-2 py-2 w-36">
                  API's
                </th>
                <th scope="col" className="px-2 py-2 w-24">
                  Actions
                </th>
                <th 
                  scope="col" 
                  className="px-2 py-2 w-24 cursor-pointer"
                  onClick={() => requestSort("last_modified")}
                >
                  Last Modified {getSortIcon("last_modified")}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedAdvertisers.map((advertiser) => (
                <tr
                  key={advertiser.id}
                  scope="row"
                  className="odd:bg-white even:bg-gray-100 hover:bg-slate-50"
                >
                  <td className="px-6 py-4 sm-break-words text-center">
                    {advertiser.name}
                  </td>
                  <td className="px-6 py-4 sm-break-words text-center">
                    <span className="inline-flex justify-center">
                      <p
                        className={`${
                          advertiser.method === "API-Direct" ? "bg-addedPink text-white" : ""
                        } ${advertiser.method === "API-Manual" ? "bg-addedLightblue text-white" : ""} ${advertiser.method === "Scrape" ? "bg-indigo-500 text-white" : ""} px-2 py-1 rounded font-bold`}
                      >
                        {advertiser.method}
                      </p>
                    </span>
                  </td>
                  <td className="px-6 py-4 text-center">
                    {advertiser.idType === "advertiser" ? 
                      advertiser.ids?.join(", ") || "-" : 
                      "-"}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {advertiser.idType === "marketplace" ? 
                      advertiser.ids?.join(", ") || "-" : "-"}
                  </td>
                  <td className={`px-6 py-4 sm-break-words ${advertiser.method === "API-Direct" ? "text-center" : ""}`}>
                    {advertiser.method === "API-Direct" ? formatApiUrl(advertiser.apis) :
                     advertiser.method === "API-Manual" ? formatApiUrl(advertiser.apis) :
                     advertiser.method === "Scrape" ? formatApiUrl(advertiser.apis) :
                     "-"}
                  </td>
                  <td className="px-6 py-2 text-center">
                    <div className="flex justify-center gap-2">
                      <button 
                        onClick={() => onEdit(advertiser)}
                        className="font-medium text-blue-500 hover:text-blue-800"
                      >
                        Edit
                      </button>
                      <button 
                        onClick={() => onDelete(advertiser.id)}
                        className="font-medium text-red-500 hover:text-red-800"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 sm-break-words text-center">
                    {advertiser.last_modified}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-center mt-2 font-extralight text-sm">
          End of {filteredAndSortedAdvertisers.length} total Advertisers
        </div>
      </div>
    </div>
  );
};

export default AdvertisersTable;
