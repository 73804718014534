import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Lottie from "lottie-react";
import loading from "../../../assets/css/loading.json";

export const DiscrepancyChart = ({ 
  chartData,
  startDate,
  endDate,
  isLoading,
  onLoadingComplete 
}) => {
  const [activeView, setActiveView] = useState("revenue");
  const [isLoadingToggled, setIsLoadingToggled] = useState(false);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState("");
  const [chartConfigs, setChartConfigs] = useState({
    revenue: null,
    impressions: null
  });

  // Add dropdown for advertiser selection
  const renderAdvertiserDropdown = () => (
    <select
      value={selectedAdvertiser}
      onChange={(e) => setSelectedAdvertiser(e.target.value)}
      className="px-4 py-2 rounded text-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      {chartData.map((advertiser) => (
        <option key={advertiser.id} value={advertiser.id}>
          {advertiser.name}
        </option>
      ))}
    </select>
  );

  // Show loading for a second when switching between views
  useEffect(() => {
    setIsLoadingToggled(true);
    setTimeout(() => setIsLoadingToggled(false), 400);
  }, [activeView]);

  // Reset chart configs when date range changes
  useEffect(() => {
    setChartConfigs({
      revenue: null,
      impressions: null
    });
  }, [startDate, endDate]);

  // Update selected advertiser when chart data changes
  useEffect(() => {
    if (chartData?.length > 0) {
      // If current selection is invalid or empty, select the first available advertiser
      const isCurrentSelectionValid = chartData.some(data => data.id === selectedAdvertiser);
      if (!isCurrentSelectionValid) {
        setSelectedAdvertiser(chartData[0].id);
      }
    }
  }, [chartData]);

  // Add loading debug
  // useEffect(() => {
  //   console.log('Chart Loading State:', isLoading);
  //   console.log('Chart Data:', chartData);
  // }, [isLoading, chartData]);

  // Modify the useEffect for chart configuration
  useEffect(() => {
    // if (!chartData?.length || !selectedAdvertiser) {
    //   console.log('No chart data or no selected advertiser');
    //   setChartConfigs({
    //     revenue: null,
    //     impressions: null
    //   });
    //   return;
    // }

    const selectedData = chartData.find(data => data.id === selectedAdvertiser);
    if (!selectedData) {
      // console.log('Selected advertiser not found in chart data:', selectedAdvertiser);
      // console.log('Available advertisers:', chartData.map(d => ({ id: d.id, name: d.name })));
      setChartConfigs({
        revenue: null,
        impressions: null
      });
      return;
    }

    if (!selectedData?.chartData?.added || !selectedData?.chartData?.demand) {
      console.log('Missing chart data for advertiser:', selectedData.name);
      console.log('Chart data structure:', selectedData.chartData);
      setChartConfigs({
        revenue: null,
        impressions: null
      });
      return;
    }

    const isSingleDate = startDate === endDate;

    try {
      // Process Added data
      const addedDataMap = new Map();
      selectedData.chartData.added
        .filter(item => !item[isSingleDate ? "hour" : "date"].startsWith("Total"))
        .forEach(item => {
          const key = isSingleDate ? 
            item.hour.split('-').pop().padStart(2, '0') : 
            item.date;
          
          if (addedDataMap.has(key)) {
            // Aggregate values for the same date
            const existing = addedDataMap.get(key);
            addedDataMap.set(key, {
              revenue: (existing.revenue || 0) + (parseFloat(item.revenue) || 0),
              impressions: (existing.impressions || 0) + (parseInt(item.impressions) || 0)
            });
          } else {
            addedDataMap.set(key, {
              revenue: parseFloat(item.revenue) || 0,
              impressions: parseInt(item.impressions) || 0
            });
          }
        });

      // Convert map to array and sort
      const addedData = Array.from(addedDataMap.entries()).map(([key, value]) => ({
        x: isSingleDate ? key : new Date(key).getTime(),
        y: value.revenue,
        impressions: value.impressions
      }));

      // Process Demand data similarly
      const demandDataMap = new Map();
      selectedData.chartData.demand
        .filter(item => !item[isSingleDate ? "hour" : "date"].startsWith("Total"))
        .forEach(item => {
          const key = isSingleDate ? 
            item.hour.split('-').pop().padStart(2, '0') : 
            item.date;
          
          if (demandDataMap.has(key)) {
            const existing = demandDataMap.get(key);
            demandDataMap.set(key, {
              revenue: (existing.revenue || 0) + (parseFloat(item.revenue) || 0),
              impressions: (existing.impressions || 0) + (parseInt(item.impressions) || 0)
            });
          } else {
            demandDataMap.set(key, {
              revenue: parseFloat(item.revenue) || 0,
              impressions: parseInt(item.impressions) || 0
            });
          }
        });

      const demandData = Array.from(demandDataMap.entries()).map(([key, value]) => ({
        x: isSingleDate ? key : new Date(key).getTime(),
        y: value.revenue,
        impressions: value.impressions
      }));

      // Sort both datasets
      [addedData, demandData].forEach(dataset => 
        dataset.sort((a, b) => {
          if (isSingleDate) {
            return parseInt(a.x) - parseInt(b.x);
          }
          return a.x - b.x;
        })
      );

      const configs = {
        revenue: {
          series: [
            {
              name: "Added Revenue",
              data: addedData.map(item => ({
                x: isSingleDate ? `${item.x}:00` : item.x,
                y: Number(item.y.toFixed(2))
              }))
            },
            {
              name: "Demand Revenue",
              data: demandData.map(item => ({
                x: isSingleDate ? `${item.x}:00` : item.x,
                y: Number(item.y.toFixed(2))
              }))
            }
          ],
          options: createChartOptions("revenue", isSingleDate)
        },
        impressions: {
          series: [
            {
              name: "Added Impressions",
              data: addedData.map(item => ({
                x: isSingleDate ? `${item.x}:00` : item.x,
                y: item.impressions
              }))
            },
            {
              name: "Demand Impressions",
              data: demandData.map(item => ({
                x: isSingleDate ? `${item.x}:00` : item.x,
                y: item.impressions
              }))
            }
          ],
          options: createChartOptions("impressions", isSingleDate)
        }
      };

      setChartConfigs(configs);
      if (onLoadingComplete) {
        onLoadingComplete();
      }
    } catch (error) {
      console.error('Error processing chart data:', error);
      setChartConfigs({
        revenue: null,
        impressions: null
      });
    }
  }, [chartData, selectedAdvertiser, startDate, endDate]);

  const createChartOptions = (type, isSingleDate) => ({
    chart: {
      type: 'area',
      height: 350,
      animations: {
        enabled: true
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: ['#ff2f86', '#2E93fA']
    },
    colors: ['#ff2f86', '#2E93fA'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.1,
        stops: [0, 100]
      }
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      offsetY: 5
    },
    xaxis: {
      type: isSingleDate ? 'category' : 'datetime',
      title: {
        text: isSingleDate ? 'Hour (UTC)' : 'Date'
      },
      labels: {
        rotate: -45,
        rotateAlways: false,
        formatter: function(value) {
          if (isSingleDate) {
            return value;
          }
          return new Date(value).toLocaleDateString();
        }
      },
      tickAmount: isSingleDate ? 24 : undefined
    },
    yaxis: {
      title: {
        text: type === 'revenue' ? 'Revenue ($)' : 'Impressions'
      },
      labels: {
        formatter: function(value) {
          if (type === 'revenue') {
            return `$${value.toFixed(2)}`;
          }
          return value.toLocaleString();
        }
      }
    },
    tooltip: {
      shared: true,
      x: {
        formatter: function(value) {
          if (isSingleDate) {
            return `${value} UTC`;
          }
          return new Date(value).toLocaleDateString();
        }
      },
      y: {
        formatter: function(value) {
          if (type === 'revenue') {
            return `$${value.toFixed(2)}`;
          }
          return value.toLocaleString();
        }
      }
    }
  });

  // Add debug info for development
  // console.log('Current state:', {
  //   selectedAdvertiser,
  //   availableAdvertisers: chartData?.map(d => ({ id: d.id, name: d.name })),
  //   hasChartData: Boolean(chartData?.length),
  //   isLoading
  // });

  return (
    <div className="mt-8">
      <div className="bg-white rounded-lg shadow">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-4">
              <h2 className="text-xl font-semibold">Chart</h2>
              {!isLoading && chartData.length > 0 && renderAdvertiserDropdown()}
            </div>
            {!isLoading && chartData.length > 0 && (
              <div className="flex gap-2">
                <button
                className={`px-4 py-2 rounded text-sm ${
                  activeView === "revenue"
                    ? "bg-gray-100 text-gray-800 font-semibold"
                    : "text-gray-500 hover:bg-gray-50 hover:text-gray-800"
                }`}
                onClick={() => setActiveView("revenue")}
              >
                Revenue
              </button>
              <button
                className={`px-4 py-2 rounded text-sm ${
                  activeView === "impressions"
                    ? "bg-gray-100 text-gray-800 font-semibold"
                    : "text-gray-500 hover:bg-gray-50 hover:text-gray-800"
                }`}
                onClick={() => setActiveView("impressions")}
              >
                Impressions
                </button>
              </div>
            )}
          </div>

          <div className="bg-white rounded-lg p-4">
            {/* {isLoading || !chartData?.length ? ( */}
            {isLoading ? (
              <div className="flex justify-center items-center h-[350px]">
                <Lottie animationData={loading} loop={true} className="h-40" />
              </div>
            ) : isLoadingToggled ? (
              <div className="flex justify-center items-center h-[350px]">
                <Lottie animationData={loading} loop={true} className="h-40" />
              </div>
            ) : chartConfigs[activeView] ? (
              <ReactApexChart
                options={chartConfigs[activeView].options}
                series={chartConfigs[activeView].series}
                type="area"
                height={350}
                key={`${selectedAdvertiser}-${startDate}-${endDate}-${activeView}`}
              />
            ) : (
              <div className="flex justify-center items-center h-[350px] text-gray-500">
                No data available
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
