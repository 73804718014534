import React from "react";

// Component to display the process status with corresponding emoji
const ProcessDisplay = ({ progress }) => {
  // Function to get the appropriate emoji based on the status
  const getStatusEmoji = (status) => {
    switch (status) {
      case "fetching":
      case "processing":
        return "🔄"; // Indicates ongoing process
      case "stopped":
        return "⛔";
      case "completed":
        return "✅"; // Indicates success
      case "error":
        return "❌"; // Indicates error
      default:
        return "➡️"; // Default status
    }
  };

  return (
    <div className="mb-4 p-4 bg-gray-100 rounded-lg">
      <h2 className="text-xl font-bold mb-2">Process Status</h2>
      <div className="flex items-center">
        {/* Display status emoji */}
        <span className="text-2xl mr-2">{getStatusEmoji(progress.status)}</span>
        {/* Display progress message */}
        <span className="text-lg">{progress.message}</span>
      </div>
    </div>
  );
};

export default ProcessDisplay;
