import { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router";
import { useAuth } from "../../auth/AuthContext";
import loading from "../../assets/css/loading.json";
import Lottie from "lottie-react";

// Login component for user authentication
export const Login = () => {
  // State variables for form inputs, error handling, button state, and loading animation
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  // Get navigation function from react-router
  const navigate = useNavigate();
  // Get the current user from the authentication context
  const { currentUser } = useAuth();

  // SVG icons for showing/hiding password
  const showPasswordSVG =
    '<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd"/></svg>';
  const hidePasswordSVG =
    '<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/></svg>';

  // Toggle the visibility of the password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Redirect to /crawler if the user is already authenticated
  useEffect(() => {
    if (currentUser) {
      navigate("/crawler");
    }
  }, [currentUser, navigate]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    setShowLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setShowLoading(false);
      navigate("/crawler");
    } catch (err) {
      if (err.code === "auth/invalid-credential"){
        setError("Bad username or password");
      } else{
        setError(err.message);
      }
      setShowLoading(false);
    }
  };

  // Enable the button only if both email and password fields are filled
  useEffect(() => {
    setIsButtonDisabled(!(email && password));
  }, [email, password]);

  return (
    <div className="flex items-center justify-center min-h-screen px-4">
      <div className="flex flex-col justify-center py-12 bg-white rounded-lg shadow-2xl w-full max-w-md border border-red-100">
        {showLoading ? (
          <Lottie animationData={loading} className="h-40 mx-auto" />
        ) : (
          <div>
            <div className="mx-auto w-full max-w-sm">
              <img alt="Added" src={Logo} className="mx-auto h-10 w-auto" />
              <hr className="mt-2"/>
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-addedLightblue">
                Sign in to your account
              </h2>
            </div>

            <div className="mt-10 mx-auto w-full max-w-sm p-6 sm:p-0">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      autoComplete="email"
                      placeholder="Enter email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="relative mt-2">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      required
                      autoComplete="current-password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      dangerouslySetInnerHTML={{
                        __html: showPassword
                          ? hidePasswordSVG
                          : showPasswordSVG,
                      }}
                      className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    ></span>
                  </div>
                </div>

                {error && (
                  <div className="text-red-500 text-sm mt-2">{error}</div>
                )}

                <div>
                  <button
                    type="submit"
                    disabled={isButtonDisabled}
                    className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm ${
                      isButtonDisabled
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-addedLightblue hover:bg-indigo-500"
                    } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-addedLightblue`}
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
