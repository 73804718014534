import React from "react";
import Lottie from "lottie-react";

const BulkUploadModal = ({
  isLoadingBulkUpload,
  uploadMethod,
  loading,
  bulkUploadFile,
  bulkUploadUrl,
  setShowBulkUploadModal,
  handleDownloadSample,
  handleUploadMethodChange,
  handleFileChange,
  handleUrlChange,
  handleBulkUploadClicked,
  bgStyle,
}) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center px-4 sm-px-0">
      <div
        className="bg-white p-5 rounded-lg shadow-xl max-w-md w-full"
        style={bgStyle}
      >
        <h2 className="text-xl mb-4 font-bold text-addedBlue">
          Bulk Upload Sellers
        </h2>
        {isLoadingBulkUpload ? (
          <Lottie animationData={loading} className="h-40" />
        ) : (
          <div>
            <p className="mb-2">
              Please upload a CSV file or provide a URL with the following
              format:
            </p>
            <p className="mb-4 text-sm text-gray-600 bg-gray-200 px-1 py-1 rounded w-fit">
              1234, SellerName, seller.domain, PUBLISHER
            </p>
            <p
              className="mb-4 text-sm text-blue-600 cursor-pointer"
              onClick={handleDownloadSample}
            >
              To download sample file, click here
            </p>
            <div className="mb-4">
              <label className="inline-flex items-center mr-4">
                <input
                  type="radio"
                  className="form-radio"
                  name="uploadMethod"
                  value="file"
                  checked={uploadMethod === "file"}
                  onChange={() => handleUploadMethodChange("file")}
                />
                <span className="ml-2">Upload File</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio"
                  name="uploadMethod"
                  value="url"
                  checked={uploadMethod === "url"}
                  onChange={() => handleUploadMethodChange("url")}
                />
                <span className="ml-2">Provide URL</span>
              </label>
            </div>
            {uploadMethod === "file" ? (
              <input
                type="file"
                onChange={handleFileChange}
                className="mb-4"
                accept=".csv"
              />
            ) : (
              <input
                type="url"
                value={bulkUploadUrl}
                onChange={handleUrlChange}
                placeholder="Enter CSV file URL"
                className="mb-4 w-full px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 border-gray-300"
              />
            )}
          </div>
        )}
        <div className="flex justify-end">
          <button
            onClick={() => setShowBulkUploadModal(false)}
            className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleBulkUploadClicked}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkUploadModal;
