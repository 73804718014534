// This component will manage the advertisers list and the selection of the advertiser
// It will allow you to create, edit and delete advertisers
// You will be able to define advertiser's method (api/manual)
// You will be able to define advertiser's api urls if needed

import NewAdvertiserModal from "../methods/NewAdvertiserModal";
import AdvertisersTable from "./AdvertisersTable";
import { useState, useEffect } from "react";
import { addAdvertiser, fetchAdvertisers, updateAdvertiser, deleteAdvertiser } from "../methods/CrudFunctions";
import toast, { Toaster } from "react-hot-toast";
import Lottie from "lottie-react";
import loading from "../../../assets/css/loading.json";

const AdvertisersManager = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingAdvertiser, setEditingAdvertiser] = useState(null);
  const [advertisers, setAdvertisers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch advertisers
  const fetchAndSetAdvertisers = async () => {
    try {
      const data = await fetchAdvertisers();
      setAdvertisers(data);
    } catch (error) {
      toast.error("Failed to fetch advertisers");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAndSetAdvertisers();
  }, []);

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  
  // Handle new/edit advertiser
  const handleAdvertiserSubmit = async (formData) => {
    setIsLoading(true);
    try {
      if (editingAdvertiser) {
        await updateAdvertiser(editingAdvertiser.id, formData);
        toast.success("Advertiser updated successfully");
      } else {
        await addAdvertiser(formData);
        toast.success("Advertiser added successfully");
      }
      await fetchAndSetAdvertisers();
    } catch (error) {
      toast.error(editingAdvertiser ? "Failed to update advertiser" : "Failed to add advertiser");
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
      setEditingAdvertiser(null);
    }
  };

  // Handle edit click
  const handleEditClick = (advertiser) => {
    setEditingAdvertiser(advertiser);
    setIsModalOpen(true);
  };

  // Handle delete
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this advertiser?")) {
      setIsLoading(true);
      try {
        await deleteAdvertiser(id);
        toast.success("Advertiser deleted successfully");
        await fetchAndSetAdvertisers();
      } catch (error) {
        toast.error("Failed to delete advertiser");
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Lottie animationData={loading} className="h-40" />
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between mt-4">
        <form className="w-[16.5rem]" onSubmit={(e) => e.preventDefault()}>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              required
            />
          </div>
        </form>
        <div>
          <button
            onClick={() => {
              setEditingAdvertiser(null);
              setIsModalOpen(true);
            }}
            className="font-bold ml-1 py-2 px-4 text-sm rounded transition duration-300 ease-in-out bg-blue-500 hover:bg-blue-600 text-white"
          >
            New Advertiser
          </button>
        </div>
      </div>

      <AdvertisersTable 
        advertisers={advertisers} 
        searchTerm={searchTerm}
        onEdit={handleEditClick}
        onDelete={handleDelete}
      />

      {isModalOpen && (
        <NewAdvertiserModal 
          onClose={() => {
            setIsModalOpen(false);
            setEditingAdvertiser(null);
          }}
          onSubmit={handleAdvertiserSubmit}
          editData={editingAdvertiser}
        />
      )}

      <Toaster position="top-right" />
    </div>
  );
};

export default AdvertisersManager;
