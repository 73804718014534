import { getFirestore, collection, getDocs, addDoc, deleteDoc, doc, updateDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { app } from "../../../firebase";

const db = getFirestore(app);

export const fetchSellers = async () => {
  const sellersCollection = collection(db, "sellers");
  const sellersSnapshot = await getDocs(sellersCollection);
  return sellersSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    last_modified: doc.data().last_modified?.toDate() // Convert Firestore Timestamp to JavaScript Date
  }));
};

export const addSeller = async (sellerData) => {
  const sellersCollection = collection(db, "sellers");
  const docRef = await addDoc(sellersCollection, {
    ...sellerData,
    last_modified: serverTimestamp() // Use serverTimestamp() from Firebase
  });
  return { id: docRef.id, ...sellerData };
};

export const deleteSeller = async (id) => {
  await deleteDoc(doc(db, "sellers", id));
};

export const deleteAllSellers = async () => {
  try {
    // Get all sellers
    const sellersRef = collection(db, "sellers");
    const sellersSnapshot = await getDocs(sellersRef);

    // Array to store all delete promises
    const deletePromises = [];

    // Iterate through all sellers and delete them
    sellersSnapshot.forEach((doc) => {
      deletePromises.push(deleteSeller(doc.id));
    });

    // Wait for all delete operations to complete
    await Promise.all(deletePromises);
    return true;
  } catch (error) {
    console.error("Error deleting all sellers:", error);
    throw error;
  }
};

export const updateSeller = async (id, sellerData) => {
  const sellerDoc = doc(db, "sellers", id);
  await updateDoc(sellerDoc, {
    ...sellerData,
    last_modified: serverTimestamp() // Update last_modified on every update
  });
};

export const fetchBackups = async () => {
  try {
    const backupsCollection = collection(db, "backups");
    const backupsSnapshot = await getDocs(backupsCollection);
    return backupsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error("Error fetching backups:", error);
    throw error;
  }
};

export const saveBackupSellers = async (sellers) => {
  try {
    const now = new Date();
    // Get the backup name
    const backupId = `${now.getFullYear()}${(now.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}_${now
      .getHours()
      .toString()
      .padStart(2, "0")}${now.getMinutes().toString().padStart(2, "0")}`;

    // Get the backup data
    const backupData = {
      timestamp: serverTimestamp(),
      sellers: sellers,
    };

    // Get the database
    const backupDocRef = doc(db, "backups", backupId);
    // console.log(backupDocRef);
    // console.log(backupData);
    // Update the database
    await setDoc(backupDocRef, backupData);
    // console.log("Backup saved successfully");
  } catch (error) {
    console.error("Error saving backup:", error);
  }
};