import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import loading from "../../../assets/css/loading.json";

export const DiscrepancyTable = ({
  tableData,
  isLoading,
  onLoadingComplete,
}) => {
  const getSeverityClass = (percentage) => {
    const absPercentage = Math.abs(percentage);

    if (absPercentage >= 20) {
      return "text-red-500 font-extrabold"; // Over 20% - Red, Extra Bold
    } else {
      return "text-red-500 font-bold"; // Under 20% - Red, Bold
    }
  };

  // Format revenue to have commas
  function formatRevenue(number) {
    try {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (error) {
      console.error("Error formatting revenue: ", error);
      return "99.9000";
    }
  }
  return (
    <div className="mt-4 px-12">
      {isLoading ? (
        <div className="bg-white rounded-lg shadow p-4 mt-2 mb-12">
          <div className="flex justify-center items-center">
            <Lottie animationData={loading} loop={true} className="h-40" />
          </div>
        </div>
      ) : (
        <div className="max-w-full overflow-x-auto flex justify-center mt-2 mb-12">
          {tableData.length > 0 && (
            <table className="w-full max-w-full overflow-x-auto text-xs text-left rtl:text-right text-gray-900 table-fixed border">
              <thead className="text-gray-700 uppercase bg-gray-200">
                <tr className="text-center">
                  <th scope="col" className="px-2 py-2 w-24">
                    Advertiser
                  </th>
                  <th scope="col" className="px-2 py-2 w-24">
                    Demand Imps
                  </th>
                  <th scope="col" className="px-2 py-2 w-24">
                    Added Imps
                  </th>
                  <th scope="col" className="px-2 py-2 w-24">
                    Demand Revenue $
                  </th>
                  <th scope="col" className="px-2 py-2 w-24">
                    Added Revenue $
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 w-24 border-l border-gray-300"
                  >
                    Imps Discrepancy %
                  </th>
                  <th scope="col" className="px-2 py-2 w-24">
                    Rev Discrepancy %
                  </th>
                  <th scope="col" className="px-2 py-2 w-24">
                    Rev Discrepancy $
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((row, index) => (
                    <tr
                      key={index}
                      className="odd:bg-white even:bg-gray-100 hover:bg-slate-50"
                    >
                      <td className="px-6 py-4 sm-break-words text-center font-bold">
                        {row.name}
                      </td>
                      <td className="px-6 py-4 sm-break-words text-center">
                        {formatRevenue(row.demand_imps)}
                      </td>
                      <td className="px-6 py-4 sm-break-words text-center">
                        {formatRevenue(row.added_imps)}
                      </td>
                      <td className="px-6 py-4 sm-break-words text-center">
                        ${formatRevenue(row.demand_revenue)}
                      </td>
                      <td className="px-6 py-4 sm-break-words text-center">
                        ${formatRevenue(row.added_revenue)}
                      </td>
                      <td
                        className={`px-6 py-4 sm-break-words text-center border-l border-gray-300 font-semibold ${
                          row.imps_discrepancy <= -0.1
                            ? "text-red-500"
                            : row.imps_discrepancy >= 0.1
                            ? "text-green-500"
                            : "text-gray-900"
                        }`}
                      >
                        {row.imps_discrepancy}%
                      </td>
                      <td
                        className={`px-6 py-4 sm-break-words text-center font-semibold ${
                          row.rev_discrepancy <= -0.1
                            ? "text-red-500"
                            : row.rev_discrepancy >= 0.1
                            ? "text-green-500"
                            : "text-gray-900"
                        }`}
                      >
                        {row.rev_discrepancy}%
                      </td>
                      <td
                        className={`px-6 py-4 sm-break-words text-center font-semibold ${
                          row.rev_discrepancy_dollar <= -0.1
                            ? "text-red-500"
                            : row.rev_discrepancy_dollar >= 0.1
                            ? "text-green-500"
                            : "text-gray-900"
                        }`}
                      >
                        ${row.rev_discrepancy_dollar}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};
