import { db } from "../../../../firebase";
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";

export const fetchAdvertisers = async () => {
  const advertisersCollection = collection(db, "advertisers");
  const advertisersSnapshot = await getDocs(advertisersCollection);
  return advertisersSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const addAdvertiser = async (advertiser) => {
  const advertisersCollection = collection(db, "advertisers");
  await addDoc(advertisersCollection, advertiser);
};

export const updateAdvertiser = async (id, advertiser) => {
  const advertiserRef = doc(db, "advertisers", id);
  await updateDoc(advertiserRef, advertiser);
};

export const deleteAdvertiser = async (id) => {
  const advertiserRef = doc(db, "advertisers", id);
  await deleteDoc(advertiserRef);
};