import { saveBackupSellers } from "./CrudFunctions";

// bulkUploadHandler.js
export const handleBulkUpload = async ({
  uploadMethod,
  bulkUploadFile,
  bulkUploadUrl,
  sellers,
  setIsLoadingBulkUpload,
  setShowBulkUploadModal,
  setSellers,
  toast,
  fetchSellers,
  addSeller
}) => {
  let text;

  if (uploadMethod === "file") {
    if (!bulkUploadFile) {
      toast.error("Please select a file first.");
      return;
    }
    text = await bulkUploadFile.text();
  } else {
    if (!bulkUploadUrl) {
      toast.error("Please enter a URL first.");
      return;
    }
    try {
      const response = await fetch(bulkUploadUrl);
      text = await response.text();
    } catch (error) {
      console.error("Error fetching URL:", error);
      toast.error(
        "Failed to fetch data from the provided URL. Please check the URL and try again."
      );
      return;
    }
  }

  setIsLoadingBulkUpload(true);
  const rows = text.split("\n").filter((row) => row.trim() !== "");
  const validSellerTypes = ["PUBLISHER", "INTERMEDIARY", "BOTH"];


  const existingSellerIds = new Set(sellers.map((seller) => seller.seller_id));
  const newSellerIds = new Set();

  const newSellers = rows
    .slice(1)
    .map((row, index) => {
      const [seller_id, seller_name, seller_domain, seller_type] = row
        .split(",")
        .map((item) => item.trim());
      return { seller_id, seller_name, seller_domain, seller_type };
    })
    .filter((seller) => {
      // Check if all fields are filled
      if (
        !seller.seller_id ||
        !seller.seller_name ||
        !seller.seller_domain ||
        !seller.seller_type
      ) {
        console.error(`Missing field(s) for seller:`, seller);
        return false;
      }

      // Check if seller_type is valid
      if (!validSellerTypes.includes(seller.seller_type.toUpperCase())) {
        console.error(`Invalid seller_type for seller:`, seller);
        return false;
      }

      // Check for duplicate IDs
      if (
        existingSellerIds.has(seller.seller_id) ||
        newSellerIds.has(seller.seller_id)
      ) {
        toast.error(`Duplicate seller_id: ${seller.seller_id}`);
        console.error(`Duplicate seller_id:`, seller.seller_id);
        return false;
      }
      newSellerIds.add(seller.seller_id);
      return true;
    });

    const updatedSellersList = [...sellers, ...newSellers];
    const formattedNewSellers = updatedSellersList.map((seller) => ({
      seller_id: seller.seller_id,
      name: seller.seller_name,
      domain: seller.seller_domain,
      seller_type: seller.seller_type,
    }));
    console.log(`✅ Bulk Upload Sellers (1/3) - Successfully uploaded all new sellers.`, formattedNewSellers)

    // Save to backup
    saveBackupSellers(formattedNewSellers);
    console.log(
      `✅ Bulk Upload Sellers (2/3) - Successfully backuped sellers`,
      formattedNewSellers
    );

    // Update json file
    const token = process.env.REACT_APP_FIREBASE_API_KEY
    try {
      const response = await fetch("https://fdn6tia2pcldgu244yhmsk43ki0rwikv.lambda-url.eu-central-1.on.aws/createjsonfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
        },
        body: JSON.stringify(formattedNewSellers),
      });

    
      // Check if the response is not successful (HTTP status code outside 200-299 range)
      if (!response.ok) {
        const errorBody = await response.text(); // Get the response body as text (or JSON if applicable)
        console.error(`Error updating JSON file: ${response.status} - ${response.statusText}`);
        console.error("Error details:", errorBody);
        toast.error("Failed to update JSON file.");
        return;
      }
    
      console.log(
        `✅ Bulk Upload Sellers (3/3) - Successfully updated file`,
        "https://docs.added.tv/sellers.json"
      );
    } catch (e) {
      // Log the entire error object
      console.error("Error updating JSON file:", e);
    
      // Check if the error is a network issue or some other type
      if (e instanceof TypeError) {
        console.error("Network or Fetch Error: ", e.message);
      } else {
        console.error("Unknown Error: ", e);
      }
    
      toast.error("Failed to update JSON file.");
    }

  if (newSellers.length === 0) {
    setIsLoadingBulkUpload(false);
    toast.error(
      "No valid sellers found in the uploaded data/found duplicated."
    );
    return;
  }

  try {
    let addedCount = 0;
    for (let seller of newSellers) {
      await addSeller(seller);
      addedCount++;
    }
    setIsLoadingBulkUpload(false);
    setShowBulkUploadModal(false);
    // Refresh the sellers list
    const updatedSellersList = await fetchSellers();
    setSellers(updatedSellersList);
    toast.success(`Successfully uploaded ${addedCount} seller(s)!`);
  } catch (error) {
    setIsLoadingBulkUpload(false);
    toast.error(`Error uploading sellers!`);
    console.error("Error during bulk upload:", error);
  }
};
