import React from "react";

// For Seller Create/Update: Function to handle add/update a seller
export const HandleAddSeller = async (
  e,
  {
    validateInputs,
    isEditing,
    sellers,
    currentSellerId,
    newSeller,
    fetchSellersData,
    setIsLoadingAddingUpdatingSeller,
    setShowSellerCreateAndUpdateModal,
    resetForm,
    saveBackupSellers,
    addSeller,
    updateSeller,
    toast,
    token,
  }
) => {
  e.preventDefault();
  if (!validateInputs()) {
    return;
  }

  if (isEditing) {
    // Find the original seller
    const originalSeller = sellers.find(
      (seller) => seller.id === currentSellerId
    );
    // console.log(`Editing seller ${originalSeller.seller_name}`);

    // Check if any changes were made
    const hasActualChanges =
      originalSeller.seller_id !== newSeller.seller_id ||
      originalSeller.seller_name !== newSeller.seller_name ||
      originalSeller.seller_domain.toLowerCase() !==
        newSeller.seller_domain.toLowerCase() ||
      originalSeller.seller_type !== newSeller.seller_type;

    if (!hasActualChanges) {
      // No changes were made
      toast("No changes were made to update", {
        icon: "⚠️",
      });
      return; // Exit the function early
    }
    try {
      // Showing Loading while Updating
      setIsLoadingAddingUpdatingSeller(true);

      // Converting existing values to lower case
      newSeller.seller_id = newSeller.seller_id.toLowerCase();
      newSeller.seller_name = newSeller.seller_name.toLowerCase();
      newSeller.seller_domain = newSeller.seller_domain.toLowerCase();

      // Update Seller in Database
      await updateSeller(currentSellerId, newSeller);
      console.log(
        `✅ Edit Seller (1/3) - Successfully Edited ${newSeller.seller_name}`,
        newSeller
      );

      // Update the Sellers State
      await fetchSellersData();

      // Create formattedSellers by replacing the old seller with the new one
      const formattedSellers = sellers.map((seller) =>
        seller.id === currentSellerId
          ? {
              seller_id: newSeller.seller_id,
              name: newSeller.seller_name,
              domain: newSeller.seller_domain,
              seller_type: newSeller.seller_type,
            }
          : {
              seller_id: seller.seller_id,
              name: seller.seller_name,
              domain: seller.seller_domain,
              seller_type: seller.seller_type,
            }
      );

      // Save to backup
      saveBackupSellers(formattedSellers);
      console.log(
        `✅ Edit Seller (2/3) - Successfully backuped sellers`,
        formattedSellers
      );

      console.log(token)
      // Update json file
      await fetch("https://fdn6tia2pcldgu244yhmsk43ki0rwikv.lambda-url.eu-central-1.on.aws/createjsonfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": token,
        },
        body: JSON.stringify(formattedSellers),
      });
      console.log(
        `✅ Edit Seller (3/3) - Successfully updated file`,
        "https://docs.added.tv/sellers.json"
      );

      // After success: close modal, hide loading, reset form and alert to user
      setShowSellerCreateAndUpdateModal(false);
      setIsLoadingAddingUpdatingSeller(false);
      resetForm();
      toast.success(`Successfully Updated ${newSeller.seller_name}!`);
    } catch (e) {
      console.error("Error updating seller:", e);
      toast.error("Failed to update seller.");
    }
  } else {
    try {
      // Showing Loading while Adding
      setIsLoadingAddingUpdatingSeller(true);

      // Converting existing values to lower case
      newSeller.seller_id = newSeller.seller_id.toLowerCase();
      newSeller.seller_name = newSeller.seller_name.toLowerCase();
      newSeller.seller_domain = newSeller.seller_domain.toLowerCase();

      // Add New Seller to Database
      await addSeller(newSeller);
      console.log(
        `✅ Add Seller (1/3) - Successfully added ${newSeller.seller_name}`,
        newSeller
      );

      // Update the Sellers State
      await fetchSellersData();
      const formattedSellers = [...sellers, newSeller].map((seller) => ({
        seller_id: seller.seller_id,
        name: seller.seller_name,
        domain: seller.seller_domain,
        seller_type: seller.seller_type,
      }));

      // Save to backup
      saveBackupSellers(formattedSellers);
      console.log(
        `✅ Add Seller (2/3) - Successfully backuped sellers`,
        formattedSellers
      );

      // Update json file
      try {
        const response = await fetch("https://fdn6tia2pcldgu244yhmsk43ki0rwikv.lambda-url.eu-central-1.on.aws/createjsonfile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": token,
          },
          body: JSON.stringify(formattedSellers),
        });

      
        // Check if the response is not successful (HTTP status code outside 200-299 range)
        if (!response.ok) {
          const errorBody = await response.text(); // Get the response body as text (or JSON if applicable)
          console.error(`Error updating JSON file: ${response.status} - ${response.statusText}`);
          console.error("Error details:", errorBody);
          toast.error("Failed to update JSON file.");
          return;
        }
      
        console.log(
          `✅ Add Seller (3/3) - Successfully updated file`,
          "https://docs.added.tv/sellers.json"
        );
      } catch (e) {
        // Log the entire error object
        console.error("Error updating JSON file:", e);
      
        // Check if the error is a network issue or some other type
        if (e instanceof TypeError) {
          console.error("Network or Fetch Error: ", e.message);
        } else {
          console.error("Unknown Error: ", e);
        }
      
        toast.error("Failed to update JSON file.");
      }
      // After success: close modal, hide loading, reset form and alert to user
      setShowSellerCreateAndUpdateModal(false);
      setIsLoadingAddingUpdatingSeller(false);
      resetForm();
      toast.success(`Successfully added ${newSeller.seller_name}!`);
    } catch (err) {
      console.error("Error adding seller:", err);
      toast.error("Failed to add seller.");
    }
  }
};
